import useDropzone from "../../../hooks/useDropzone";
import { setOrderType } from "../../../redux/orderSlice";
import { useAppDispatch } from "../../../redux/store";
import DropZone from "../OrderManually/DropZone";
import WithModalTitle from "../WithModalTitle/WithModalTitle";


function OrderFile() {
   const dispatch = useAppDispatch()
   const { submitExcel } = useDropzone()

   return (
      <div>
         <WithModalTitle title={ 'Импортировать файл' }>
            <DropZone
               accept={ { 'application/vnd.ms-excel': [ '.xls', '.xlsx' ] } }
               dropzoneDescription={ 'Перетащите файл сюда (формат XLS до 5 МБ)' }
               submitHandler={ submitExcel }
               onSubmitSuccess={ () => dispatch( setOrderType( 'xls_success' ) ) }
            />
         </WithModalTitle>

      </div>
   )
}


export default OrderFile