import s from "../../components/Table/Table.module.scss"
import { useCallback, useState } from 'react'
import { Collapse } from "@mui/material"
import RowButtons from "./RowButtons";
import TableMatching from "./TableMatching/TableMatching";
import HelpMassage from "../../components/HelpMessage/HelpMassage";
import useAlert from "../../hooks/useAlert";
import useHelpMessage from "../../hooks/useHelpMessage";
import useOrdersRequest from "../../hooks/useOrdersRequest";
import { STATUS } from "../../utils/constants";
import { TStatus } from "../../utils/types";


function TableRow( { row, updateMyData }: any ) {
   const { updateOrder } = useOrdersRequest()
   const { alertSuccess } = useAlert()
   const [ collapse, setCollapse ] = useState( false )
   const toggleBtnStyle = collapse ? { transform: 'rotate(-180deg)' } : {}
   const {
      helpMessageDone,
      helpMessageWarning,
   } = useHelpMessage( row.original )

   function collapseHandler(): void {
      setCollapse( prev => !prev )
   }

   const saveData = useCallback( async ( status?: TStatus ): Promise<void> => { 
      const payload = status ? { ...row.original, status } : row.original
      const res = await updateOrder( JSON.stringify( payload ) )
      if ( !res.error ) alertSuccess( 'Сохранено' )
   }, [ row ] )

   const saveData2 = useCallback( async ( status2?: TStatus ): Promise<void> => { 
      const payload = status2 ? { ...row.original, status2 } : row.original
      if (payload.status2s === "Согласовано" && status2 === "Согласовано") {
         payload.status = "Согласовано"
      }
      if (payload.status2 === "Не согласовано") {
         payload.status = "Не согласовано"
      }
      const res = await updateOrder( JSON.stringify( payload ) )
      if ( !res.error ) alertSuccess( 'Сохранено' )
   }, [ row ] )

   const saveData2s = useCallback( async ( status2s?: TStatus ): Promise<void> => { 
      const payload = status2s ? { ...row.original, status2s } : row.original
      if (payload.status2 === "Согласовано" && status2s === "Согласовано") {
         payload.status = "Согласовано"
      }
      if (payload.status2s === "Не согласовано") {
         payload.status = "Не согласовано"
      }
      const res = await updateOrder( JSON.stringify( payload ) )
      if ( !res.error ) alertSuccess( 'Сохранено' )
   }, [ row ] )

   const messageTheme = () => {
      if ( row.original.closed ) return 'disabled'
      if ( row.original.status === STATUS.WATCH || row.original.closed ) return 'done'
      if ( row.original.status === STATUS.APPROVE ) return 'success'
      return 'reject'
   }


   return (
      <div className={ `
         ${ s.Table__row } 
         ${ row.isSelected ? s.checked : '' }
         ${ row.disabled ? s.disabled : '' }
      ` }>
         <div className={ s.Table__row_main }>

            {/*@ts-ignore*/ }
            { row.cells.map( cell => {
                  const { key, role } = cell.getCellProps()
                  return <div key={ key } role={ role }>{ cell.render( 'Cell' ) }</div>
               }
            ) }
            <div>
               <button className={ s.Table__collapse_btn } onClick={ collapseHandler }>
                  <div className={ s.Table__arrow_icon } style={ toggleBtnStyle }/>
               </button>
            </div>
         </div>

         <Collapse in={ collapse }>
            <div className={ s.Table__row_table }>

               <TableMatching row={ row } updateMyData={ updateMyData }/>

               <div className={ s.Table__help_message }>

                  { helpMessageDone() &&
                     <HelpMassage theme={ messageTheme() }>
                        { helpMessageDone() }
                     </HelpMassage>
                  }
                  { helpMessageWarning() &&
                     <HelpMassage theme={ 'warning' }>
                        { helpMessageWarning() }
                     </HelpMassage> }
               </div>

               <RowButtons saveData={ saveData } saveData2={ saveData2 } saveData2s={ saveData2s } row={ row }/>
            </div>
         </Collapse>
      </div>
   )
}


export default TableRow