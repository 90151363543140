import s from "./Table.module.scss";
import { memo } from 'react';
import { Button } from "@megafon/ui-core";
import useRole from "../../hooks/useRole";
import { STATUS, TYPE } from "../../utils/constants";
import { TStatus } from "../../utils/types";


function RowButtons( { saveData, saveData2, saveData2s, row }: {
   saveData: ( status?: TStatus ) => void
   saveData2: ( status2?: TStatus ) => void
   saveData2s: ( status2s?: TStatus ) => void
   row: any
} ) {
   const {
      isStatusApprove,
      isStatusNoApprove,
      isShowBtns,
      isShowBtnSave,
      isShowBtnsConfirm,
      isOperator_s_a,
      isAll,
   } = useRole( row.original )
   const isSectorAgent = row.original.type === TYPE.IN_SECTOR_AGENT;
   if ( isShowBtns ) return null
   return (
      <div className={ s.Table__btns }>
         
         { isShowBtnsConfirm && !isOperator_s_a && !isAll && isSectorAgent &&
            <>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  disabled={ row.original.status2 === STATUS.APPROVE }
                  onClick={ () => saveData2(STATUS.APPROVE) }
               >{ isStatusApprove ? 'Согласовано 1' : 'Согласовать 1' }</Button>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  className={ s.red_btn }
                  disabled={ row.original.status2 === STATUS.NO_APPROVE }
                  onClick={ () => saveData2(STATUS.NO_APPROVE) }
               >{ isStatusNoApprove ? 'Отказано 1' : 'Отказать 1' } в переносе</Button>
            </>
         }

         { isShowBtnsConfirm && isOperator_s_a && !isAll && isSectorAgent &&
            <>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  disabled={ row.original.status2s === STATUS.APPROVE }
                  onClick={ () => saveData2s(STATUS.APPROVE) }
               >{ isStatusApprove ? 'Согласовано 2' : 'Согласовать 2' }</Button>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  className={ s.red_btn }
                  disabled={ row.original.status2s === STATUS.NO_APPROVE }
                  onClick={ () => saveData2s(STATUS.NO_APPROVE) }
               >{ isStatusNoApprove ? 'Отказано 2' : 'Отказать 2' } в переносе</Button>
            </>
         }

         { isShowBtnsConfirm && (!isSectorAgent || isAll) &&
            <>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  disabled={ row.original.status === STATUS.APPROVE  }
                  onClick={ () => saveData( STATUS.APPROVE ) }
               >{ isStatusApprove ? 'Согласовано' : 'Согласовать' }</Button>
               {/* @ts-ignore */ }
               <Button
                  theme="black"
                  type="outline"
                  className={ s.red_btn }
                  disabled={ row.original.status === STATUS.NO_APPROVE }
                  onClick={ () => saveData( STATUS.NO_APPROVE ) }
               >{ isStatusNoApprove ? 'Отказано' : 'Отказать' } в переносе</Button>
            </>
         }

         { isShowBtnSave &&
            // @ts-ignore
            <Button
               className={ s.btn_save }
               disabled={ !row.original.changed }
               onClick={ () => saveData() }
            >Сохранить данные</Button>
         }

      </div>
   )
}

export default memo( RowButtons );