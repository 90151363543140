import s from './CellField.module.scss'
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { TextField } from "@megafon/ui-core";
import moment from "moment";
import { TFiledType } from "../../utils/types";

type TCellField = {
   value: string
   label: string
   onChange: ( e: ChangeEvent<HTMLElement> ) => void
   onBlur: () => void
   disabled: boolean
   type?: TFiledType
}

function CellField( { value, label, onChange, onBlur, disabled, type }: TCellField ) {
   const [ focus, setFocus ] = useState( false )
   const formatValue = label === 'Дата и время'
      ? moment( value ).format( 'DD.MM · HH:mm' )
      : value

   function onFocus() {
      setFocus( true )
   }

   function onBlurHandler() {
      onBlur()
      setTimeout( () => setFocus( false ), 100 )
   }

   function onChangeHandler( e: ChangeEvent<HTMLElement> ) {
      onChange( e )
      !focus && onFocus()
   }

   function onKeyUp( e: KeyboardEvent<HTMLElement> ) {
      if ( e.key === 'Enter' ) (e.target as HTMLInputElement).blur()
   }


   return (
      type === 'tel'
         ? <div title={ formatValue }><TextField
            type={ type }
            className={ s._ }
            placeholder="+7 (999) 999-99-99"
            mask="+7 (999) 999-99-99"
            value={ formatValue }
            label={ label }
            inputMode="numeric"
            hideIcon={ !focus }
            onChange={ onChangeHandler }
            onBlur={ onBlurHandler }
            onFocus={ onFocus }
            onKeyUp={ onKeyUp }
         /></div>
         : <div title={ formatValue }><TextField
            type={ type }
            className={ s._ }
            placeholder={ ' ' }
            value={ formatValue }
            label={ label }
            onChange={ onChangeHandler }
            onBlur={ onBlurHandler }
            onFocus={ onFocus }
            hideIcon={ !focus }
            disabled={ disabled }
            onKeyUp={ onKeyUp }
         /></div>
   )
}

export default CellField;
