import s from "./UserHoliday.module.scss";
import { FormEvent, useEffect, useState } from "react";
import { Button, Select, Switcher } from "@megafon/ui-core";
import useAlert from "../../hooks/useAlert";
import useOperatorsRequest from "../../hooks/useOperatorsRequest";
import { useAppSelector } from "../../redux/store";
import { TSelectFiled, TUser } from "../../utils/types";


function UserHoliday( { user }: {
   user: TUser
} ) {
   const { alertSuccess } = useAlert()
   const { setHolidayResponsible, getResponsibles } = useOperatorsRequest()
   const [ checked, setChecked ] = useState( false )
   const [ select, setSelect ] = useState<number>( 0 )
   const responsibles = useAppSelector( state =>
      state.tableOperators.responsibles.reduce( ( acc: TSelectFiled[], resp ) => {
         if ( resp.id === user.id ) return acc
         acc.push( {
            title: resp.name,
            value: resp.id
         } )
         return acc
      }, [] )
   )

   function onSelectHandler( _: any, select: TSelectFiled ): void {
      setSelect( select.value as number )
   }

   async function onSubmit( e: FormEvent ) {
      e.preventDefault()

      const res = await setHolidayResponsible( {
         user_id: user.id,
         responsible_id: user.responsible_id ? 0 : select
      } )
      const responsibles = await getResponsibles() 
      if ( res.meta.requestStatus !== 'fulfilled' || responsibles.meta.requestStatus !== 'fulfilled' ) return
      alertSuccess( 'Сохранено' )

      if ( user.responsible_id ) setChecked( false )
   }

   useEffect( () => {
      if ( !checked ) setSelect( 0 )
   }, [ checked ] )


   return (
      <form className={ s._ } onSubmit={ onSubmit }>
         <h3 className={ s.title }>Отметка об отпуске:</h3>

         <div className={ s.switch_row }>
            <p>В отпуске</p>
            <Switcher
               checked={ !!user.responsible_id || checked }
               onChange={ () => setChecked( prev => !prev ) }
                
            />
         </div>

         <Select
            required
            className={ s.select }
            label={ 'Выберите ответственного вместо вас' }
            items={ responsibles }
            currentValue={ user.responsible_id || select }
            onSelect={ onSelectHandler as any }
            disabled={ !checked || !!user.responsible_id }
         />

         {/*@ts-ignore*/ }
         <Button
            actionType={ 'submit' }
            disabled={ !( user.responsible_id || select ) }
         >
            { user.responsible_id ? 'Отменить' : 'Подтвердить' }
         </Button>
      </form>
   )
}


export default UserHoliday;