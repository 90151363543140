import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../utils/api";
import { setCookie } from "../../utils/helpers/setCookie";
import { TFormData, TUser } from "../../utils/types";


export const thunkLogin = createAsyncThunk<TUser, TFormData>(
   'auth/thunkLogin',
   async ( data, { rejectWithValue } ) => {
      try {
         const res = await request.post( 'auth', data )
         if ( res.data ) {
            setCookie( 'user', JSON.stringify( res.data ) )
            return res.data
         }
         throw new Error( 'Ошибка ' + res.status )
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)

export const thunkSetHolidayResponsible = createAsyncThunk<
   TUser,
   { user_id: number, responsible_id: number }
>(
   'auth/thunkSetHolidayResponsible',
   async ( { user_id, responsible_id }, { rejectWithValue } ) => {
      try {
         const res = await request( `auth/holiday?user_id=${ user_id }&responsible_id=${ responsible_id }`, )
         if ( res.data ) {
            setCookie( 'user', JSON.stringify( res.data ) )
            return res.data
         }
         throw new Error( 'Ошибка ' + res.status )
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)


export const thunkResetPassord = createAsyncThunk<TUser, string>(
   'users/fetchByIdStatus',
   async (email, { rejectWithValue }) => {
      try {
         const res = await request( `auth/reset?email=${ email }`, )
         if ( res.data ) {
            setCookie( 'user', JSON.stringify( res.data ) )
            return res.data
         }
         throw new Error( 'Ошибка ' + res.status )
      } catch ( err: any ) {
         return rejectWithValue(err)
      }
   },
 )

