import s from "./TableMatching.module.scss";
import { useCallback } from "react";
import useDropzone from "../../../hooks/useDropzone";
import useModal from "../../../hooks/useModal";
import { setAlert } from "../../../redux/alertSlice";
import { useAppDispatch } from "../../../redux/store";
import { thunkDownloadFiles } from "../../../redux/tableOrders/tableOrdersThunks";
import { TRow } from "../../../utils/types";
import Modal from "../../Modal/Modal";
import DropZone from "../../Modal/OrderManually/DropZone";
import WithModalTitle from "../../Modal/WithModalTitle/WithModalTitle";


function AttachmentsBtns( { row }: {
   row: any,
} ) {
   const { visible, showModal, closeModal } = useModal()
   const dispatch = useAppDispatch()
   const { submitAttachments } = useDropzone()

   async function download( row: any ): Promise<void> {
      if ( row.disabled || !row.original.images ) return
      dispatch( thunkDownloadFiles( row.original as TRow ) )
   }

   function uploadFileHandler() {
      if ( row.disabled ) return
      showModal()
   }

   const onSubmitSuccess = useCallback( async () => {
      await closeModal()
      dispatch( setAlert( { message: 'Вложения добавлены' } ) )
   }, [ dispatch ] )


   return (
      <div>
         <div
            className={ `
               ${ s.icon } 
               ${ s.upload_icon }
               ${ row.disabled ? s.download_icon__empty : '' }
            ` }
            title={ 'Загрузить' }
            onClick={ uploadFileHandler }
         />
         <div
            className={ `
               ${ s.icon } 
               ${ s.download_icon } 
               ${ !( row.original as TRow ).images || row.disabled ? s.download_icon__empty : '' }
            ` }
            title={ ( row.original as TRow ).images ? 'Скачать' : 'Вложения отсутствуют' }
            onClick={ () => download( row ) }
         />

         <Modal onClose={ closeModal } isShow={ visible }>
            <WithModalTitle
               title={ 'Добавить основание' }
               description={ 'Приложите файлы, которые могут являться <br/> основанием для отказа.' }
            >
               <DropZone
                  accept={ { 'image/jpeg': [] } }
                  dropzoneDescription={ 'Перетащите файл сюда (формат JPG до 5 МБ)' }
                  submitHandler={ submitAttachments }
                  onSubmitSuccess={ onSubmitSuccess }
                  row={ row }
               />
            </WithModalTitle>
         </Modal>
      </div>
   )
}

export default AttachmentsBtns;