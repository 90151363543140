import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../utils/api";
import { TOperator, TThunkOperatorsResponse } from "../../utils/types";


export const thunkGetResponsibles = createAsyncThunk<
   { responsibles: TOperator[] }
>(
   'tableOperators/thunkGetResponsibles',
   async ( _, { rejectWithValue } ) => {
      try {
         const { data, status } = await request( `operators/responsibles` )
         if ( status === 200 ) return data
         throw new Error( 'Ошибка ' + status )
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)

export const thunkGetOperators = createAsyncThunk<
   TThunkOperatorsResponse,
   any
>(
   'tableOperators/thunkGetOperators',
   async ( { method, payload, query = '' }, { rejectWithValue } ) => {
      try {
         const { data, status } = await request( `operators${ query }`, {
            method,
            data: payload
         } )
         if ( status === 200 ) return data
         throw new Error( 'Ошибка ' + status )
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)
