import { object, string } from "yup";
import { column } from "../../data/table";
import { TColumn, TColumnKeys } from "../types";

// получить данные и валидацию полей
function getFields( filedKeys: TColumnKeys[] ): {
   fields: TColumn[],
   validationSchema: any
} {
   return {
      fields: filedKeys.map( key => column[key] ),
      validationSchema: object().shape(
         filedKeys.reduce( ( acc: any, k ) => {
            if ( k === 'email' ) return { ...acc, [k]: string().email().required() }
            if ( k === 'password' ) return { ...acc, [k]: string().min( 6 ).required() }
            return { ...acc, [k]: string() }
         }, {} )
      )
   }
}

export default getFields