import s from "../OrderManually/OrderManually.module.scss"
import { BaseSyntheticEvent, FC, memo, SyntheticEvent, useState } from "react";
import { Button, Preloader } from "@megafon/ui-core";
import { useDropzone } from "react-dropzone";
import { Row } from "react-table";
import img from '../../../assets/images/type-order/load_file.png'
import { useAppSelector } from "../../../redux/store";
import Loader from "../../Loader/Loader";


type TDropZoneProps = {
   dropzoneDescription: string
   accept: Record<string, string[]>
   submitHandler: ( files: File[], row?: Row ) => Promise<any>
   onSubmitSuccess: () => void
   skip?: boolean
   row?: Row
}


const DropZone: FC<TDropZoneProps> = ( { dropzoneDescription, accept, submitHandler, onSubmitSuccess, skip, row } ) => {
   const isLoading = useAppSelector( state => state.tableOrders.status === 'loading' )

   const [ files, setFiles ] = useState<Array<File>>( [] )
   const { getRootProps, getInputProps } = useDropzone( {
      accept,
      onDrop: files => {
         setFiles( prev => [ ...prev, ...files ] )
      },
      onDropRejected: files => {
         console.log( files )
      },
      maxSize: 5000000,
      disabled: isLoading,
   } )

   function deleteFile( e: SyntheticEvent, i: number ) {
      e.stopPropagation()
      setFiles( files.filter( ( _, idx ) => idx !== i ) )
   }

   async function submitOrder( e: BaseSyntheticEvent ) {
      e.stopPropagation()
      const res = await submitHandler( files, row )
      if ( !res.error ) onSubmitSuccess()
   }


   return (
      <div className={ s.OrderManually__form_container_step4 }>
         { isLoading && <Loader preloader={ false } color={ 'black' }/> }

         <div { ...getRootProps() }>

            { isLoading
               ? <div className={ s.preloader }>
                  { isLoading && <Preloader/> }
               </div>
               : <img src={ img } alt="load_file" height={ 144 }/>
            }

            { files.length > 0
               ? <>
                  { !!files.length &&
                     <ul className={ s.OrderManually__files }>
                        { files.map( ( file, i ) =>
                           <li key={ i }>{ file.name }
                              <button type={ 'button' } onClick={ ( e: SyntheticEvent ) => deleteFile( e, i ) }/>
                           </li>
                        ) }
                     </ul>
                  }

                  <div className={ s.OrderManually__form_container_step4_btns }>
                     <label>
                        <input { ...getInputProps() }/>
                        {/*@ts-ignore*/ }
                        <Button type={ 'outline' } theme={ 'black' }>
                           Добавить еще файл
                        </Button>
                     </label>
                     {/*@ts-ignore*/ }
                     <Button onClick={ submitOrder }>Загрузить</Button>
                  </div>
               </>
               : <>

                  <p>{ dropzoneDescription }</p>

                  <div className={ s.OrderManually__form_container_step4_btns }>
                     <label>
                        <input { ...getInputProps() }/>
                        {/*@ts-ignore*/ }
                        <Button>Загрузить с компьютера</Button>
                        { skip &&
                           // @ts-ignore
                           <Button onClick={ submitOrder } type={ 'outline' } theme={ 'black' }>Пропустить</Button> }
                     </label>
                  </div>

               </>
            }
         </div>
      </div>

   )
}


export default memo( DropZone )