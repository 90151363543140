// @ts-nocheck
import Checkbox from "../../components-ui/Checkbox/Checkbox";


const checkboxToggleHandler = ( hooks, setIsVisibleDeletePanel ) => {
   hooks.visibleColumns.push( ( columns ) => [
      {
         id: 'selection',
         Header: ( { getToggleAllRowsSelectedProps, rows } ) => {
            const hasNoDisabled = rows.some( row => !row.disabled )
            return <div><Checkbox
               { ...getToggleAllRowsSelectedProps() }
               disabled={ !hasNoDisabled }
               setIsVisibleDeletePanel={ setIsVisibleDeletePanel }
            /></div>
         },
         Cell: ( { row } ) =>
            <div><Checkbox
               { ...row.getToggleRowSelectedProps() }
               row={ row }
               setIsVisibleDeletePanel={ setIsVisibleDeletePanel }/></div>
      },
      ...columns,
   ] )
}


export default checkboxToggleHandler
