import options from "./selectOptions";
import { TYPE } from "../utils/constants";
import { TColumn, TColumnKeys } from "../utils/types";


export const column: Record<TColumnKeys, TColumn> = {
   account_name: {
      Header: 'Учетное имя',
      CellTitle: 'Учетное имя',
      accessor: 'account_name', // accessor is the "key" in the data
      tooltip: 'Учетное имя',
   },
   type: {
      Header: 'Тип переноса',
      CellTitle: 'Тип',
      options: options.type.filter( ( i ) => i.value !== TYPE.IN_SECTOR ) ,
      accessor: 'type',
      tooltip: 'Тип переноса tooltip',
   },
   ccmp: {
      Header: 'CCMP',
      CellTitle: 'CCMP',
      accessor: 'ccmp',
      tooltip: 'Номер переносимой заявки CCMP',
      placeholder: 'Номер переносимой заявки CCMP',
   },
   msisnd: {
      Header: 'MSISND',
      CellTitle: 'MSISND',
      accessor: 'msisnd',
      tooltip: 'tooltip MSISND',
   },
   crm: {
      Header: 'CRM',
      CellTitle: 'CRM',
      accessor: 'crm',
      tooltip: 'Номер переносимой заявки CRM',
      placeholder: 'Номер переносимой заявки CRM',
   },
   createdat: {
      Header: 'Время внесения',
      CellTitle: 'Дата и время',
      accessor: 'createdat',
      sort: true,
      disabled: true
   },
   status: {
      Header: 'Согласование',
      CellTitle: 'Статус',
      // options: options.status,
      accessor: 'status',
      tooltip: 'tooltip test',
      disabled: true
   },
   transfer: {
      Header: 'Услуга',
      CellTitle: 'Услуга',
      options: options.transfer,
      accessor: 'transfer',
      tooltip: 'tooltip test',
   },
   cause_transfer: {
      Header: 'Причина переноса',
      CellTitle: 'Причина',
      accessor: 'cause_transfer',
      tooltip: 'tooltip test',
   },
   cause_rejection: {
      Header: 'Причина отказа',
      CellTitle: 'Причина',
      accessor: 'cause_rejection',
      tooltip: 'tooltip test',
   },
   before_order_number: {
      Header: 'Родительская',
      CellTitle: 'Номер заявки',
      accessor: 'before_order_number',
      tooltip: 'Номер родительской заявки CRM',
      placeholder: 'Номер родительской заявки CRM',
   },

   id: {
      Header: 'Номер заявки',
      CellTitle: 'Номер',
      accessor: 'id',
      tooltip: 'tooltip col1',
      disabled: true
   },
   ex_seller: {
      Header: 'Бывший продавец',
      CellTitle: 'ФИО',
      accessor: 'ex_seller',
      tooltip: 'tooltip col2',
   },
   next_seller: {
      Header: 'Будущий продавец',
      CellTitle: 'ФИО',
      accessor: 'next_seller',
      tooltip: 'tooltip col3',
   },
   author: {
      Header: 'Кто внес позицию',
      CellTitle: 'ФИО',
      accessor: 'author',
      tooltip: 'tooltip col4',
      disabled: true
   },
   responsible_id: {
      Header: 'Ответственный 1',
      CellTitle: 'ФИО',
      options: [], // получаем из useSelectResponsibles()
      accessor: 'responsible_id',
      tooltip: 'tooltip col5',
   },
   responsible_agent_id: {
      Header: 'Ответственный 2',
      CellTitle: 'ФИО',
      options: [], // получаем из useSelectResponsibles()
      accessor: 'responsible_agent_id',
      tooltip: 'tooltip col51',
   },
   duplicate: {
      Header: 'Дубли заявок',
      CellTitle: 'Кол-во',
      accessor: 'duplicate',
      tooltip: 'tooltip col6',
      disabled: true
   },
   attachments: {
      Header: 'Вложения',
      CellTitle: '',
      accessor: 'attachments',
      tooltip: 'tooltip col7',
   },

   name: {
      Header: 'ФИО',
      CellTitle: 'ФИО',
      accessor: 'name',
   },
   role: {
      Header: 'Роль',
      CellTitle: 'Роль',
      accessor: 'role',
      options: options.role,
   },
   email: {
      Header: 'Почта',
      CellTitle: 'Почта',
      accessor: 'email',
      type: 'email'
   },
   company: {
      Header: 'Компания',
      CellTitle: 'Компания',
      accessor: 'company',
   },
   department: {
      Header: 'Отдел',
      CellTitle: 'Отдел',
      accessor: 'department',
   },
   supervisor: {
      Header: 'Руководитель',
      CellTitle: 'ФИО',
      accessor: 'supervisor',
   },
   phone: {
      Header: 'Телефон',
      CellTitle: 'Телефон',
      accessor: 'phone',
      type: "tel",
   },
   password: {
      Header: 'Пароль',
      CellTitle: 'Пароль',
      accessor: 'password',
   },
}

export const columns: TColumn[] = [
   column.type,
   column.transfer,
   column.crm,
   column.account_name,
   column.ccmp,
   column.msisnd,
   column.status,
   column.createdat,
   column.duplicate,
]

export const matchingColumns: TColumn[] = [
   column.before_order_number,
   column.author,
   column.responsible_id,
   column.responsible_agent_id,
   column.ex_seller,
   column.next_seller,
   column.cause_transfer,
   column.cause_rejection,
   column.attachments,
]

export const operatorColumns: TColumn[] = [
   column.name,
   column.email,
   column.role,
   column.supervisor,
   column.department,
   column.phone,
   // column.company,
]