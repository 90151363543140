import s from "../../components/MainHeader/MainHeader.module.scss";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Button } from "@megafon/ui-core";
import ROUTES from "../../utils/routes";


function ButtonBack( { className = '' }: {
   className?: string
} ) {
   const location = useLocation()
   const navigate = useNavigate()

   function onClickBack() {
      navigate( location.state?.from ?? ROUTES.ORDERS )
   }


   if ( location.pathname !== ROUTES.SETTINGS ) return null
   return (
      // @ts-ignore
      <Button
         onClick={ onClickBack }
         className={ `${ s.btn_back } ${ className }` }
         type={ 'text' }
         theme={ 'black' }
         showArrow
      >Вернуться назад
      </Button>
   )
}


export default ButtonBack;