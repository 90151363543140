import { useCallback } from "react";
import { read, utils } from "xlsx";
import useOrdersRequest from "./useOrdersRequest";
import { useAppSelector } from "../redux/store";
import getSelectTitleByValue from "../utils/helpers/getSelectTitleByValue";
import { TOrderExel } from "../utils/types";


function useDropzone() {
   const { createOrder, uploadOrders, updateOrder } = useOrdersRequest()

   const selectUser = (state: any) => state.auth.user;
   const selectOrderForm = (state: any) => state.order.data;
   const selectTab = (state: any) => state.tableOrders.tab.value;
   const selectPagination = (state: any) => state.tableOrders.pagination.current; 
   const selectFilterBy = (state: any) => state.tableOrders.filterBy; 
   const selectUserId = (state: any) => state.auth.user?.id ?? '';
   const selectResponsibles = (state: any) => state.tableOperators.responsibles;
   const user = useAppSelector(selectUser);
   const orderForm = useAppSelector(selectOrderForm);
   const tab = useAppSelector(selectTab);
   const pagination = useAppSelector(selectPagination); 
   const filterBy = useAppSelector(selectFilterBy);  
   const userId = useAppSelector(selectUserId);
   const responsibles = useAppSelector(selectResponsibles);
    

   function getFormDataFiles( files?: File[] ) {
      const formData = new FormData()
      formData.append( 'orderForm', JSON.stringify( orderForm ) )
      formData.append( 'tab', tab )
      formData.append( 'pagination', `${ pagination }` )
      filterBy && formData.append( filterBy, `${ userId }` )
      files?.forEach( ( file, i ) => formData.append( 'img' + i, file ) )

      return formData
   }

   // добавить вложения заявки
   const submitAttachments = useCallback( ( files: File[], row: any ) => {
      return updateOrder( JSON.stringify( row.original ), files )
   }, [ orderForm, pagination, tab ] )


   // загрузить вложения заявки
   const submitManually = useCallback( ( files: File[] ) => {
      const formData = getFormDataFiles( files )
      return createOrder( formData )
   }, [ orderForm, pagination, tab ] )


   // загрузить excel файл с заявками
   const submitExcel = useCallback( async ( files: File[] ): Promise<any> => {
      const filesData = await Promise.all( files.map( f => f.arrayBuffer() ) )

      const orders = filesData.map( f => {
         const wb = read( f )
         return utils.sheet_to_json( wb.Sheets[wb.SheetNames[0]] )
      } ).flat() as TOrderExel[]

      // записать ФИО автора при загрузке excel в БД
      const ordersWithCurrentAuthor = orders.map( el => {
         if ( user ) {
            el['Кто внес позицию'] = user.name
            el.author_id = user.id
            el['Ответственный 1'] = getSelectTitleByValue( responsibles, el['Ответственный 1'] )
            el['Ответственный 2'] = getSelectTitleByValue( responsibles, el['Ответственный 2'] )
         }
         return el
      } )

      return uploadOrders( ordersWithCurrentAuthor )
   }, [ user ] )


   return { submitManually, submitExcel, submitAttachments }
}

export default useDropzone;