import { useCallback, useState } from "react";
import { object, string } from "yup";
import useSelectResponsibles from "./useSelectResponsibles";
import useSelectResponsiblesAgent from "./UseSelectResponsiblesAgent";
import { column } from "../data/table";
import { useAppSelector } from "../redux/store";
import { TRANSFER, TYPE } from "../utils/constants";
import { TColumn, TColumnKeys } from "../utils/types";


/**
 Валидация полей при добавлении заявки:
 - Если выбрана услуга SIM поле MSISDN обязательно к заполнению, для других услуг это поле не обязательно заполнять
 - Если выбрана услуга конвергент FMC поле заявка ccmp обязательно к заполнению, для других услуг не обязательно
 - Причина переноса/Номер заявки - не обязательно
 - Поле "Учетное имя" обязательно для заполнения, если выбрана одна из услуг: "LTE", "Интернет Wifire", "ТВ", "Телефония"
 */
function UseValidateOrder( keys: TColumnKeys[] ): {
   required: ( accessor: TColumnKeys ) => boolean,
   fields: TColumn[],
   validationSchema: any
} {
   const form = useAppSelector( state => state.order.data )
   const { optionsResponsibles } = useSelectResponsibles()
   const { optionsResponsiblesAgent } = useSelectResponsiblesAgent()
   const isAccountName = form.transfer === TRANSFER.LTE
      || form.transfer === TRANSFER.WIFIRE
      || form.transfer === TRANSFER.TV
      || form.transfer === TRANSFER.PHONE


   const [ data ] = useState( () => {
      return {
         fields: keys.map( key => {
            if ( key === 'responsible_id' ) { // записать ответственных для select
               return { ...column[key], options: optionsResponsibles }
            }
            if ( key === 'responsible_agent_id' ) { // записать ответственных для select
               return { ...column[key], options: optionsResponsiblesAgent }
            }
            return column[key]
         } ),
         validationSchema: object().shape(
            keys.reduce( ( acc: any, k ) => {
               if ( k === 'cause_transfer' ) return acc 
               if ( k === 'responsible_agent_id' ) {
                  if ( form.type === TYPE.IN_SECTOR_AGENT ) {
                     return { ...acc, [k]: string().required() }
                  }
                  return acc
               }
               if ( k === 'account_name' ) {
                  if ( isAccountName ) {
                     return { ...acc, [k]: string().required() }
                  }
                  return acc
               }
               if ( k === 'before_order_number' ) {
                  if ( form.type === TYPE.PROTOCOL ) {
                     return { ...acc, [k]: string().required() }
                  }
                  return acc
               }
               if ( k === 'msisnd' ) {
                  if ( form.transfer === TRANSFER.SIM ) {
                     return { ...acc, [k]: string().required() }
                  }
                  return acc
               }
               if ( k === 'ccmp' ) {
                  if ( form.transfer === TRANSFER.FMC ) {
                     return { ...acc, [k]: string().required() }
                  }
                  return acc
               }
               return { ...acc, [k]: string().required() }
            }, {} )
         )
      }
   } )

   const required = useCallback( ( accessor: TColumnKeys ): boolean => {
      switch ( accessor ) {
         case 'cause_transfer':
            return false
         case 'account_name':
            return isAccountName
         case 'before_order_number':
            return form.type === TYPE.PROTOCOL
         case 'responsible_agent_id':
            return form.type === TYPE.IN_SECTOR_AGENT
         case 'msisnd':
            return form.transfer === TRANSFER.SIM
         case 'ccmp':
            return form.transfer === TRANSFER.FMC
         default:
            return true
      }
   }, [ form ] )

   return { required, ...data }
}

export default UseValidateOrder;