import { useAppSelector } from "../redux/store";


function UseSelectGetNameResponsible() {
   const responsibles = useAppSelector( state => state.tableOperators.responsibles )

   function getUserNameById( id?: number ): string {
      const user = responsibles.find( el => el.id === id )
      return user?.name ?? ''
   }

   return { getUserNameById }
}


export default UseSelectGetNameResponsible;