import s from "./DownloadOrder.module.scss";
import { useState } from "react";
import { Button, Select, TextField } from "@megafon/ui-core";
import { Field, Form, Formik } from "formik";
import DownloadDatepicker from "./DownloadDatepicker";
import options from "../../../data/selectOptions";
import useAlert from "../../../hooks/useAlert";
import useSelectResponsibles from "../../../hooks/useSelectResponsibles";
import { request } from "../../../utils/api";
import downloadExcel from "../../../utils/helpers/downloadExel";


type TFormData = {
   author: string
   transfer: string
   responsible_id: string
}

function DownloadForm() {
   const [ date, setDate ] = useState<Record<string, Date | null>>( { from: null, to: null } )
   const { alertWarning } = useAlert()
   const { optionsResponsibles } = useSelectResponsibles()

   // выгрузить в excel
   async function download( data: TFormData ) {
      const dateTo = date.to || new Date()
      const { responsible_id, author, transfer } = data

      const { data: orders } = await request( `orders/xlsx?transfer=${ transfer }&author=${ author }&responsible_id=${ responsible_id }` +
         (date.from ? `&dateFrom=${ date.from }&dateTo=${ dateTo }` : '') )

      downloadExcel( orders, 'Заявки.xlsx', alertWarning )
   }


   return (
      <Formik
         initialValues={ {
            author: '',
            transfer: '',
            responsible_id: '',
         } }
         onSubmit={ download }>
         { ( { setFieldValue, values } ) =>
            <Form className={ s.form }>
               <fieldset>
                  <Field
                     as={ TextField }
                     hidePlaceholder
                     name={ 'author' }
                     label={ 'Автор обращения на перенос' }
                  />
                  <Field
                     as={ Select }
                     name="transfer"
                     label={ 'Тип услуги' }
                     items={ options.transfer }
                     onSelect={ ( e: Event, { value }: { value: string } ) => setFieldValue( 'transfer', value ) }
                     currentValue={ values.transfer }
                  />
                  <Field
                     as={ Select }
                     name={ 'responsible_id' }
                     label={ 'Ответственный 1' }
                     items={ optionsResponsibles }
                     onSelect={ ( e: Event, { value }: { value: string } ) => setFieldValue( 'responsible_id', value ) }
                     currentValue={ values.responsible_id }
                  />

                  <DownloadDatepicker date={ date } setDate={ setDate }/>

               </fieldset>
               {/* @ts-ignore */ }
               <Button actionType="submit" className={ s.btn_submit }>
                  Выгрузить
               </Button>

            </Form>
         }
      </Formik>
   )
}

export default DownloadForm