import s from './UserContactNameRole.module.scss';
import { TUser } from "../../../utils/types";


function UserContactNameRole( { user }: {
   user: TUser
} ) {
   return (
      <div className={ s._ }>
         <h2 className={ s.name }>{ user.name }</h2>
         <p className="text-gray">{ user.role }</p>
      </div>
   )
}


export default UserContactNameRole;