import { thunkSetHolidayResponsible, thunkResetPassord } from "../redux/auth/authThunks";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { thunkGetOperators, thunkGetResponsibles, } from "../redux/tableOperators/tableOperatorsThunks";
import { TOperatorForm } from "../utils/types";


/** Запросы для таблицы операторов */
function UseOperatorsRequest() {
   const dispatch = useAppDispatch()

   const selectData = (state: any) => state.tableOperators.operators;
   const selectSelectId = (state: any) => state.tableOperators.selectedId;
   const selectPagination = (state: any) => state.tableOperators.pagination?.current ?? 1;
   const selectTab = (state: any) => state.tableOperators.tab.value;
   const selectSearch = (state: any) => state.tableOperators.search;
   const data = useAppSelector(selectData);
   const selectId = useAppSelector(selectSelectId);
   const pagination = useAppSelector(selectPagination);
   const tab = useAppSelector(selectTab);
   const search = useAppSelector(selectSearch);
 

   return {
      /** Получить операторов */
      getOperators: ( query: string ) => dispatch( thunkGetOperators( {
         method: 'GET',
         query
      } ) ),
      /** Создать оператора */
      createOperator: ( row: TOperatorForm ) => dispatch<any>( thunkGetOperators( {
         method: 'POST',
         payload: {
            tab,
            row
         }
      } ) ),
      /** Обновить оператора */
      updateOperator: ( rowIndexChanged: number ) => dispatch( thunkGetOperators( {
         method: 'PUT',
         payload: {
            row: data[rowIndexChanged],
            pagination,
            tab,
            search
         }
      } ) ),
      /** Удалить операторов */
      deleteOperators: () => dispatch( thunkGetOperators( {
         method: 'DELETE',
         payload: {
            id: selectId,
            pagination,
            tab,
            search
         }
      } ) ),
      /** Получить ответственных */
      getResponsibles: () => dispatch( thunkGetResponsibles() ),
      /** Записать ответственного на время отпуска */
      setHolidayResponsible: ( ids: {
         user_id: number,
         responsible_id: number
      } ) => dispatch( thunkSetHolidayResponsible( ids ) ),
      /** Записать ответственного на время отпуска */
      resetPassword: ( email: string ) => dispatch(thunkResetPassord(email))
   }
}

export default UseOperatorsRequest;