import s from "./TableOperatos.module.scss"
import { Row, UseRowSelectRowProps } from "react-table";
import ButtonResetOperator from "./ButtonResetOperator";

function TableRow( { row }: { row: UseRowSelectRowProps<Row> } ) {
   return (
      <div className={ `${ s.Table__row } ${ row.isSelected ? s.checked : '' }` }>
         <div className={ s.Table__row_main }> 
               {/*@ts-ignore*/ }
               { row.cells.map( cell => {
                     const { key, role } = cell.getCellProps()
                     
                     return <div key={ key } role={ role } title={ cell.value }>{ cell.render( 'Cell' ) }</div>
                  }
               ) }
                <div> 
                  {/*@ts-ignore*/ }

               <ButtonResetOperator email={row.values.email} />
               </div>
         </div>

      </div>
   )
}


export default TableRow