import { ReactNode } from "react";
import useRole from "../../hooks/useRole";

/**
 * - заблокировать строку
 * - редактировать могут только созданные лично заявки
 * - редактирование происходит до момента согласования/несогласования
 * - корректировка согласованных/несогласованных заявок в отчетном месяце закрывается
 * */
function WithRowDisabled( { children, row }: {
   children: ReactNode
   row: any
} ) {
   const { isRowDisabled } = useRole( row.original )

   if ( isRowDisabled ) {
      row.disabled = true
      row.isSelected = false
   }

   return children
}


export default WithRowDisabled;