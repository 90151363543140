import s from "./HolidayResponsible.module.scss";
import { Button } from "@megafon/ui-core";
import WithModalTitle from "../WithModalTitle/WithModalTitle";


function HolidayResponsible( { responsibleName, closeModal }: {
   responsibleName: string
   closeModal: () => void
} ) {
   return (
      <>
         <WithModalTitle
            title={ 'Ответственный изменен' }
            description={ `На данный момент он находится в отпуске. Вместо него ответственный ${ responsibleName }` }
         />
         <div className={ s.btn_modal }>
            {/*@ts-ignore*/ }
            <Button onClick={ closeModal }>Понятно</Button>
         </div>
      </>
   )
}


export default HolidayResponsible;