import { COMPANY, DEPARTMENT, ROLE, STATUS, TRANSFER, TYPE } from "../utils/constants";
import { TOptions } from "../utils/types";

const options: TOptions = {
   type: [
      { title: TYPE.PROTOCOL, value: TYPE.PROTOCOL },
      { title: TYPE.IN_SECTOR_AGENT, value: TYPE.IN_SECTOR_AGENT },
      { title: TYPE.RESALE, value: TYPE.RESALE },
      { title: TYPE.OTHER, value: TYPE.OTHER },
      { title: TYPE.IN_SECTOR, value: TYPE.IN_SECTOR },
   ],
   transfer: [
      { title: TRANSFER.FMC, value: TRANSFER.FMC },
      { title: TRANSFER.SIM, value: TRANSFER.SIM },
      { title: TRANSFER.LTE, value: TRANSFER.LTE },
      { title: TRANSFER.WIFIRE, value: TRANSFER.WIFIRE },
      { title: TRANSFER.TV, value: TRANSFER.TV },
      { title: TRANSFER.PHONE, value: TRANSFER.PHONE },
      { title: TRANSFER.CONSOLE, value: TRANSFER.CONSOLE },
      { title: TRANSFER.ROUTER, value: TRANSFER.ROUTER },
      { title: TRANSFER.OTHER, value: TRANSFER.OTHER },
   ],
   role: [
      { title: ROLE.OPERATOR, value: ROLE.OPERATOR },
      { title: ROLE.OPERATOR_S, value: ROLE.OPERATOR_S },
      { title: ROLE.OPERATOR_S_A, value: ROLE.OPERATOR_S_A },
      { title: ROLE.ADMIN, value: ROLE.ADMIN },
   ],
   status: [
      { title: STATUS.APPROVE, value: STATUS.APPROVE },
      { title: STATUS.NO_APPROVE, value: STATUS.NO_APPROVE },
   ],
   company: [
      { title: COMPANY.NBN, value: COMPANY.NBN },
      { title: COMPANY.MF, value: COMPANY.MF },
   ],
   department: [
      { title: DEPARTMENT.B2C, value: DEPARTMENT.B2C },
      { title: DEPARTMENT.B2B, value: DEPARTMENT.B2B },
   ],
}

export default options