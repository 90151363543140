import s from "../OrderManually/OrderManually.module.scss"
import { Button } from "@megafon/ui-core";
import { Form, Formik } from "formik"
import Fieldset from "../../../components-ui/Fieldset/Fieldset";
import Range from "../../../components-ui/Range/Range";
import useValidateOrder from "../../../hooks/useValidateOrder";
import { setOrderData } from "../../../redux/orderSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { TOrderFormStep3 } from "../../../utils/types";


function OrderManuallyStep3() {
   const dispatch = useAppDispatch()
   const {
      responsible_id,
      responsible_agent_id,
      cause_transfer,
      ex_seller,
      next_seller
   } = useAppSelector( store => store.order.data as TOrderFormStep3 )

   const { required, fields, validationSchema } = useValidateOrder( [
      'responsible_id', 'responsible_agent_id','cause_transfer', 'ex_seller', 'next_seller',
   ] )

   function submitHandler( data: TOrderFormStep3 ) {
      dispatch( setOrderData( data ) )
   }


   return (
      <div className={ s.OrderManually }>

         <Range className={ s.OrderManually__range } range={ 90 }/>
         <Formik
            initialValues={ {
               responsible_id,
               responsible_agent_id,
               cause_transfer,
               ex_seller,
               next_seller,
            } }
            validateOnBlur
            validationSchema={ validationSchema }
            onSubmit={ submitHandler }>
            { formik =>
               <Form className={ s.OrderManually__form }>
                  <Fieldset fields={ fields } formik={ formik } required={ required }/>

                  {/*@ts-ignore*/ }
                  <Button
                     actionType="submit"
                     className={ s.OrderManually__btn }
                  >Далее</Button>
               </Form> }
         </Formik>

      </div>
   )
}

export default OrderManuallyStep3