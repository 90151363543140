import { createSlice } from "@reduxjs/toolkit"
import { thunkGetOperators, thunkGetResponsibles } from "./tableOperatorsThunks";
import { OPERATORS_ALL } from "../../utils/constants";
import { TOperator, TOperatorsCount, TPaginationResponse, TTabValue, TThunkStatus } from "../../utils/types";


type TTableOperatorsState = {
   operators: TOperator[],
   responsibles: TOperator[],
   count: TOperatorsCount,
   pagination: TPaginationResponse
   tab: { value: TTabValue, index: number },
   selectedId: number[]
   status: TThunkStatus,
   search: string,
   error: string,
}


const initialState: TTableOperatorsState = {
   operators: [],
   responsibles: [],
   count: {
      all: 0,
      new: 0,
   },
   pagination: {
      current: 1,
      total: 0
   },
   tab: { value: OPERATORS_ALL, index: 0 },
   selectedId: [],
   status: null,
   search: '',
   error: '',
}

const tableOperatorsSlice = createSlice( {
   name: 'tableOperators',
   initialState,
   reducers: {
      setSearch( state, action ) {
         state.search = action.payload
      },
      setCurrentPagination( state, action ) {
         state.pagination.current = action.payload
      },
      setTab( state, action ) {
         state.tab = action.payload
      },
      setCellOperators( state, action ) {
         const { rowIndex, columnId, value } = action.payload

         state.operators = state.operators.map( ( row, index ) => {
            if ( index === rowIndex ) {
               return {
                  ...state.operators[rowIndex],
                  [columnId]: value,
                  changed: true
               }
            }
            return row
         } )
      },
      selectOperators( state, action ) {
         state.selectedId = action.payload
      }
   },
   extraReducers: builder => {
      builder
         .addCase( thunkGetOperators.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkGetOperators.fulfilled, ( state, action ) => {
            const { operators, pagination, count } = action.payload
            state.operators = operators
            state.count = count || state.count
            state.pagination = pagination || state.pagination
            state.status = 'success'
            state.error = ''
         } )
         .addCase( thunkGetOperators.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )

      builder
         .addCase( thunkGetResponsibles.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkGetResponsibles.fulfilled, ( state, action ) => {
            const { responsibles } = action.payload
            state.responsibles = responsibles
            state.status = 'success'
            state.error = ''
         } )
         .addCase( thunkGetResponsibles.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )
   }
} )


export const {
   setSearch,
   setCurrentPagination,
   setCellOperators,
   selectOperators,
   setTab
} = tableOperatorsSlice.actions
export default tableOperatorsSlice.reducer


