import { useAppSelector } from "../redux/store";


const UseQuery = ( tableKey: 'tableOrders' | 'tableOperators' ): string => {

   const selectPagination = (state: any) => state[tableKey].pagination?.current ?? 1;
   const selectCurrentTab = (state: any) => state[tableKey].tab.value;
   const selectSortStatus = (state: any) => state.tableOrders.sortStatus ?? '';
   const selectSearch = (state: any) => state[tableKey].search ?? '';
   const selectFilterBy= (state: any) => state.tableOrders.filterBy;
   const selectUserId = (state: any) => state.auth.user?.id ?? ''; 
   const pagination = useAppSelector(selectPagination);
   const currentTab = useAppSelector(selectCurrentTab);
   const sortStatus = useAppSelector(selectSortStatus);
   const search = useAppSelector(selectSearch);
   const filterBy = useAppSelector(selectFilterBy);
   const userId = useAppSelector(selectUserId); 


   return `?pagination=${ pagination }&tab=${ currentTab }`
      + ( sortStatus ? `&sort=${ sortStatus }` : '' )
      + ( search ? `&search=${ search }` : '' )
      + ( filterBy ? `&${ filterBy }=${ userId }` : '' )

}

export default UseQuery;