import s from "./Fieldset.module.scss";
import { Fragment, memo, useState } from "react";
import { Select, TextField } from "@megafon/ui-core";
import { Field } from "formik";
import HolidayResponsible from "../../components/Modal/HolidayResponsible/HolidayResponsible";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import UseSelectResponsibles from "../../hooks/useSelectResponsibles";
import { validateError } from "../../utils/helpers/validate";
import { TColumn, TColumnKeys, TSelectFiled } from "../../utils/types";


function Fieldset( { fields, formik, required }: {
   fields: TColumn[],
   formik: any,
   required?: ( accessor: TColumnKeys ) => boolean
} ) {
   const { getHolidayResponsibleId } = UseSelectResponsibles()
   const { visible, closeModal, showModal } = useModal()
   const [ responsibleHoliday, setResponsibleHoliday ] = useState( '' )

   function onSelect( e: Event, select: TSelectFiled, accessor: TColumnKeys ) {
      if (select) {
         const responsible = getHolidayResponsibleId( +select.value )
         if ( !responsible ) return formik.setFieldValue( accessor, select.value )
         setResponsibleHoliday( responsible.name )
         showModal()
         formik.setFieldValue( accessor, responsible.id )
      } else {
         return formik.setFieldValue( accessor, '' )
      }
   }

   return (
      <fieldset className={ s._ }>
         { fields.map( ( field: TColumn ) => {
            const verification = validateError( formik.errors[field.accessor], formik.touched[field.accessor], formik.dirty )
            const isRequired: boolean = required ? required( field.accessor ) : true

            return <Fragment key={ field.accessor }>
               { field.options
                  ? <><Field
                     className={ s.select }
                     type="combobox"
                     as={ Select }
                     required={ isRequired }
                     name={ field.accessor }
                     label={ field.placeholder || field.Header }
                     items={ field.options }
                     onSelect={ ( e: Event, select: TSelectFiled ) => onSelect( e, select, field.accessor ) }
                     currentValue={ formik.values[field.accessor] }
                     verification={ verification }
                  />
                  </>
                  : <Field
                     as={ TextField }
                     required={ isRequired }
                     hidePlaceholder
                     type={ field.type }
                     name={ field.accessor }
                     label={ field.placeholder || field.Header }
                     verification={ verification }
                  />
               }


            </Fragment>

         } ) }
         <Modal onClose={ closeModal } isShow={ visible }>
            <HolidayResponsible responsibleName={ responsibleHoliday } closeModal={ closeModal }/>
         </Modal>
      </fieldset> )
}

export default memo( Fieldset );