// @ts-nocheck
import s from "./TableMatching.module.scss";
import { FC, useEffect, useState } from "react";
import { Row, useTable } from "react-table";
import AttachmentsBtns from "./AttachmentsBtns";
import { matchingColumns as columns } from '../../../data/table'
import WithRowDisabled from "../../_hocs/WithRowDisabled";
import defaultColumn from "../EditableCell";
import TableColumn from "../TableColumn";


type TTableMatchingProps = {
   row: Row
   updateMyData: () => void
}

const TableMatching: FC<TTableMatchingProps> = ( { row: parentRow, updateMyData } ) => {
   const data = [ parentRow.original ]
   const [ skipPageReset, setSkipPageReset ] = useState( false )
   const {
      headerGroups,
      rows,
      prepareRow,
   } = useTable( { columns, data, defaultColumn, autoResetPage: !skipPageReset, updateMyData } )

   useEffect( () => {
      setSkipPageReset( false )
   }, [ data ] )

   // function onChange( value, cell ) {
   //    const { column } = cell
   //    updateMyData( parentRow.index, column.id, value )
   // }


   return (
      <div className={ s.TableMatching }>
         { headerGroups.map( headerGroup => {
               const { key, role } = headerGroup.getHeaderGroupProps()
               return <div key={ key } role={ role } className={ s.TableMatching__head }>
                  { headerGroup.headers.map( column =>
                     <TableColumn key={ column.id } column={ column }/>
                  ) }
               </div>
            }
         ) }

         { rows.map( row => {
            prepareRow( row )
            const { key, role } = row.getRowProps()
            return (
               <WithRowDisabled key={ key } row={ row }>
                  <div role={ role } className={ s.TableMatching__row }>
                     <div className={ s.TableMatching__row_main }>
                        { row.cells.map( cell => {
                              cell.row.index = parentRow.index
                              const { key, role } = cell.getCellProps()

                              if ( cell.column.id === 'attachments' ) return (
                                 <AttachmentsBtns row={ row } key={ key }/>
                              )

                              return <div key={ key } role={ role }>{ cell.render( 'Cell' ) }</div>
                           }
                        ) }
                     </div>
                  </div>
               </WithRowDisabled>

            )
         } ) }

      </div>
   )
}


export default TableMatching