import { useAppSelector } from "../redux/store";
import { ROLE } from "../utils/constants";


function UseDisabledCheckbox() {
   const user = useAppSelector( state => state.auth.user )

   return {
      /**
       * - Пометить на удаление согласованную/несогласованную заявку может только старший оператор или администратор.
       * - Закрытую заявка пометить нельзя
       * */
      isCheckboxDisabled: ( row: any ) => {
         if ( !row ) return
         const { author_id, closed } = row.original
         if ((user?.role !== ROLE.ADMIN && author_id !== user?.id) || (closed && user?.role !== ROLE.ADMIN)) {
            row.isSelected = false
            return true
         }
      }
   }

}

export default UseDisabledCheckbox;