import s from './TabsBox.module.scss'
import { FC, memo } from "react";
import { Tabs, Tab } from "@megafon/ui-core";
import { TTab } from "../../utils/types";


type TTabsBox = {
   tabs: TTab[]
   onTabClick: ( index: number ) => void
   defaultIndex: number
}


const TabsBox: FC<TTabsBox> = ( { tabs, onTabClick, defaultIndex } ) => {
   return (
      <Tabs
         defaultIndex={defaultIndex}
         onTabClick={onTabClick}
         classes={{ swiperWrapper: s.swiperWrapper }}
         >
         {tabs.map(({ title, count }) =>
            title !== 'Все' ? (
               <Tab key={title} title={`${title} ${count}`} />
            ) : (
               <Tab key={title} title={title} />
            )
         )}
         </Tabs>

   )
}


export default memo( TabsBox )