import s from "./UserContactCard.module.scss";
import { ReactNode } from "react";


function UserContactCard( { icon, title, text }: {
   icon: ReactNode,
   title: string
   text: string
} ) {
   return (
      <li className={ s._ }>
         <div className={ s.icon }>{ icon }</div>
         <div>
            <h5 className="text-gray">{ title }</h5>
            <p>{ text }</p>
         </div>
      </li>
   )
}


export default UserContactCard;