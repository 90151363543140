import s from './HelpMessage.module.scss'
import { ReactNode } from "react";


function HelpMassage( { children, theme }: {
   children: ReactNode,
   theme: 'done' | 'warning' | 'reject' | 'success' | 'disabled'
} ) {

   return (
      <div className={ `${ s.HelpMassage } ${ s[`HelpMassage_${ theme }`] }` }>
         <div className={ s.HelpMassage__image }>
            <div className={ s.HelpMassage__icon }/>
         </div>
         <p className={ s.HelpMassage__text }>{ children }</p>
      </div>
   )
}


export default HelpMassage