import s from './AddOperator.module.scss';
import { useCallback, FC } from "react";
import { Button } from "@megafon/ui-core";
import { Form, Formik } from "formik";
import Fieldset from "../../../components-ui/Fieldset/Fieldset";
import useOperatorsRequest from "../../../hooks/useOperatorsRequest";
import { useAppSelector } from "../../../redux/store";
import getFields from "../../../utils/helpers/getFields";
import { TOperatorForm, TColumnKeys } from "../../../utils/types";
 


type TAddOperatorProps = {
   setSuccess: ( success: boolean ) => void
}

const AddOperator: FC<TAddOperatorProps> = ( { setSuccess } ) => {
   const { createOperator, getResponsibles } = useOperatorsRequest()
   const { isLoading } = useAppSelector( state => ( {
      tab: state.tableOperators.tab.value,
      isLoading: state.tableOperators.status === 'loading'
   } ) )

   const { fields, validationSchema } = getFields( [ 'name', 'email', 'role', 'supervisor', 'department', 'phone' ] )

   async function submitHandler( data: TOperatorForm ) {
      const res = await createOperator( data )
      setSuccess( !res.error )
      getResponsibles()
   }

   const required = useCallback( ( accessor: TColumnKeys ): boolean => {
      switch ( accessor ) {
         case 'name':
            return true 
         case 'email':
            return true 
         case 'role':
            return true 
         default:
            return false
      }
   }, [  ] ) 


   return (
      <div className={ s._ }>
         <Formik
            initialValues={ {
               name: '',
               role: '',
               email: '',
            } }
            validateOnBlur
            validationSchema={ validationSchema }
            onSubmit={ submitHandler }>
            { formik =>
               <Form className={ s.form }>
                  <Fieldset fields={ fields } formik={ formik } required={required}/>
                  { /*@ts-ignore*/ }
                  <Button
                     actionType="submit"
                     className={ s.btn_submit }
                     showLoader={ isLoading }
                     disabled={ isLoading }
                  >
                     Добавить
                  </Button>

               </Form>
            }
         </Formik>
      </div>
   )
}


export default AddOperator;