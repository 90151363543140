import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import alert from './alertSlice'
import auth from './auth/authSlice'
import modal from './modalSlice'
import order from './orderSlice'
import tableOperators from './tableOperators/tableOperatorsSlice'
import tableOrders from './tableOrders/tableOrdersSlice'


const store = configureStore( {
   reducer: {
      auth,
      alert,
      modal,
      order,
      tableOrders,
      tableOperators,
   },
   devTools: process.env.NODE_ENV === 'development',
} )


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch

export default store