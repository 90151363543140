import { createSlice } from "@reduxjs/toolkit"
import { thunkLogin, thunkSetHolidayResponsible } from "./authThunks";
import { TThunkStatus, TUser } from "../../utils/types";


type TAuthState = {
   user: null | TUser,
   status: TThunkStatus
   error: string,
}

const initialState: TAuthState = {
   user: null,
   status: null,
   error: ''
}

const authSlice = createSlice( {
   name: 'auth',
   initialState,
   reducers: {
      setUser( state, action ) {
         state.user = action.payload
      },
      signout( state ) {
         state.user = null
      }
   },
   extraReducers: builder => {
      builder
         .addCase( thunkLogin.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkLogin.fulfilled, ( state, action ) => {
            state.user = action.payload
            state.status = 'success'
            state.error = ''
         } )
         .addCase( thunkLogin.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )

      builder
         .addCase( thunkSetHolidayResponsible.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkSetHolidayResponsible.fulfilled, ( state, action ) => {
            state.user = action.payload
            state.error = ''
         } )
         .addCase( thunkSetHolidayResponsible.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )
   }
} )

export const { signout, setUser } = authSlice.actions
export default authSlice.reducer