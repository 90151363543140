import { useAppSelector } from "../redux/store";
import { ROLE } from "../utils/constants";
import { TOperator } from "../utils/types";


/** Получить ответственных для Select */
function UseSelectResponsibles() {
   const selectReposibles = (state: any) => state.tableOperators.responsibles;
   const responsibles = useAppSelector(selectReposibles);

   const optionsResponsibles = responsibles.filter((r: any) => (r.role === ROLE.OPERATOR_S || r.isall)).sort((a: any, b: any) => a.name.localeCompare(b.name)).map( (r: any) => ( { title: r.name, value: r.id } ) )
   /** Получить замещающего ответственного на время отпуска */
   function getHolidayResponsibleId( responsibleId: number ): TOperator | undefined {
      const user = responsibles.find( (user: any) => user.id === responsibleId )
      if ( !user ) return
      return responsibles.find( (r: any) => r.id === user.responsible_id )
   }

   return { optionsResponsibles, getHolidayResponsibleId }
}


export default UseSelectResponsibles;