import s from './Loader.module.scss'
import { Preloader } from "@megafon/ui-core";

type TTableOverlay = {
   preloader?: boolean
   withOpacity?: boolean
   color?: 'default' | 'white' | 'black'
}

function Loader( { preloader = true, color = 'default', withOpacity = true }: TTableOverlay ) {
   const opacity = withOpacity ? s.opacity : ''

   return (
      <div className={ `${ s._ } ${ opacity }` }>
         { preloader && <Preloader color={ color }/> }
      </div>
   )
}

export default Loader;