import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { thunkDownloadFiles, thunkGetOrders } from "./tableOrdersThunks";
import { ORDERS_ALL } from "../../utils/constants";
import {
   TCounterOnResponsible,
   TFilterBy,
   TOrdersCount,
   TPaginationResponse,
   TResendResponsibles,
   TRow,
   TSortStatus,
   TTabValue,
   TThunkStatus,
   TUpdateMyData
} from "../../utils/types";


type TTableOrdersState = {
   orders: TRow[]
   count: TOrdersCount
   pagination: TPaginationResponse
   tab: { value: TTabValue, index: number }
   sortStatus: TSortStatus
   selectedId: number[]
   search: string
   status: TThunkStatus
   error: string
   filterBy: TFilterBy
   resend_responsibles?: TResendResponsibles[]
   counter_on_responsible?: TCounterOnResponsible
}

const initialState: TTableOrdersState = {
   orders: [],
   count: {
      all: 0,
      agreed: 0,
      noagreed: 0,
      watch: 0,
   },
   pagination: {
      current: 1,
      total: 0
   },
   tab: { value: ORDERS_ALL, index: 0 },
   sortStatus: 'DESC',
   selectedId: [],
   search: '',
   status: null,
   error: '',
   filterBy: '',
   resend_responsibles: [],
   counter_on_responsible: undefined,
}

const tableOrdersSlice = createSlice( {
   name: 'tableOrders',
   initialState,
   reducers: {
      clearUploadInfo( state ) {
         state.resend_responsibles = []
         state.counter_on_responsible = undefined
      },
      selectOrdersFilter( state, action ) {
         state.filterBy = action.payload
      },
      setSortStatus( state, action ) {
         state.sortStatus = action.payload
      },
      setCurrentPagination( state, action ) {
         state.pagination.current = action.payload
      },
      setSearch( state, action ) {
         state.search = action.payload
      },
      setCurrentTab( state, action ) {
         state.tab = action.payload
      },
      setCellOrders( state, action: PayloadAction<TUpdateMyData> ) {
         const {
            rowIndex,
            columnId,
            value,
            // responsible_id
         } = action.payload

         state.orders = state.orders.map( ( row, index ) => {
            if ( index === rowIndex ) {
               return {
                  ...state.orders[rowIndex],
                  [columnId]: value,
                  // responsible_id,
                  changed: true
               }
            }
            return row
         } )
      },
      changedOff( state, action ) {
         const id = action.payload

         state.orders = state.orders.map( ( order: any ) => {
            if ( order.changed !== undefined && order.id === id ) order.changed = false
            return order
         } )
      },
      selectOrders( state, action: PayloadAction<number[]> ) {
         state.selectedId = action.payload
      },
   },
   extraReducers: builder => {
      builder
         .addCase( thunkGetOrders.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkGetOrders.fulfilled, ( state, { payload } ) => {
            state.orders = payload.orders
            state.count = payload.count
            state.pagination = payload.pagination
            state.sortStatus = payload.sortStatus
            state.resend_responsibles = payload.resend_responsibles
            state.counter_on_responsible = payload.counter_on_responsible
            state.status = 'success'
            state.error = ''
         } )
         .addCase( thunkGetOrders.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )

      builder
         .addCase( thunkDownloadFiles.pending, ( state ) => {
            state.status = 'loading'
            state.error = ''
         } )
         .addCase( thunkDownloadFiles.fulfilled, ( state ) => {
            state.status = 'success'
            state.error = ''
         } )
         .addCase( thunkDownloadFiles.rejected, ( state, action ) => {
            state.status = 'error'
            state.error = action.payload as string
         } )
   }
} )


export const {
   clearUploadInfo,
   selectOrdersFilter,
   setSortStatus,
   setCurrentPagination,
   setSearch,
   setCurrentTab,
   setCellOrders,
   selectOrders,
   changedOff,
} = tableOrdersSlice.actions
export default tableOrdersSlice.reducer


