import { useAppSelector } from "../redux/store";
import { ROLE } from "../utils/constants";
import { TOperator } from "../utils/types";


/** Получить ответственных для Select */
function UseSelectResponsiblesAgent() {
   const selectReposibles = (state: any) => state.tableOperators.responsibles;
   const responsibles = useAppSelector(selectReposibles);
 
   /** Получить замещающего ответственного на время отпуска */
   const optionsResponsiblesAgent = responsibles.filter((r: any) =>(r.role === ROLE.OPERATOR_S_A || r.isall)).sort((a: any, b: any) => a.name.localeCompare(b.name)).map( (r: any) => ( { title: r.name, value: r.id } ) )
 
   optionsResponsiblesAgent.unshift({title: ' ', value: 0});
   function getHolidayResponsibleId( responsibleId: number ): TOperator | undefined {
      const user = responsibles.find( (user: any) => user.id === responsibleId )
      if ( !user ) return
      return responsibles.find( (r: any) => r.id === user.responsible_id )
   }

   return { optionsResponsiblesAgent, getHolidayResponsibleId }
}


export default UseSelectResponsiblesAgent;