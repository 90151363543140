// @ts-nocheck
import s from "./Table.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useRowSelect, useTable } from "react-table";
import defaultColumn from "./EditableCell";
import TableColumn from "./TableColumn";
import TableRow from "../../components/Table/TableRow";
import { columns } from "../../data/table";
import useRowDisabled from "../../hooks/useDisabledCheckbox";
import useOrdersRequest from "../../hooks/useOrdersRequest";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { selectOrders, setCellOrders } from "../../redux/tableOrders/tableOrdersSlice";
import checkboxToggleHandler from "../../utils/helpers/checkboxToggleHandler";
import WithRowDisabled from "../_hocs/WithRowDisabled";
import DeletePanel from "../DeletePanel/DeletePanel";


function Table() {
   const dispatch = useAppDispatch()
   const { deleteOrders } = useOrdersRequest()
   
   const selectData = (state: any) => state.tableOrders.orders
   const data = useAppSelector(selectData);
   const [ isVisibleDeletePanel, setIsVisibleDeletePanel ] = useState( false )
   const { isCheckboxDisabled } = useRowDisabled()

   const [ skipPageReset, setSkipPageReset ] = useState( false )
   const {
      getTableProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      getTableBodyProps,
   } = useTable( { columns, data, defaultColumn, autoResetPage: !skipPageReset, updateMyData },
      useRowSelect,
      hooks => checkboxToggleHandler( hooks, setIsVisibleDeletePanel )
   )

   // Изменить данные таблицы
   function updateMyData( data ) {
      setSkipPageReset( true ) // Включаем флаг, чтобы не сбрасывать страницу
      dispatch( setCellOrders( data ) )
   }

   useEffect( () => {
      setSkipPageReset( false )
   }, [ data ] )

   /** Mассив id выделенных заявок */
   const selectedRows = useMemo( (): number[] =>
         selectedFlatRows.reduce( ( acc, row ) => {
            if ( isCheckboxDisabled( row ) ) return acc
            acc.push( row.original.id )
            return acc
         }, [] )
      , [ selectedFlatRows ] )

   useEffect( () => {
      dispatch( selectOrders( selectedRows ) )
   }, [ selectedRows ] )


   if ( !data.length ) return null
   return (
      <div className={ s.Table } { ...getTableProps() }>

         { headerGroups.map( headerGroup => {
               const { key, role } = headerGroup.getHeaderGroupProps()
               return <div key={ key } role={ role } className={ s.Table__head }>
                  { headerGroup.headers.map( column =>
                     <TableColumn key={ column.render( 'Header' ) } column={ column }/>
                  ) }
                  <div/>
               </div>
            }
         ) }

         <div { ...getTableBodyProps() }>
            { rows.map( ( row, i ) => {
               prepareRow( row )
               const { key, role } = row.getRowProps()
               return <WithRowDisabled key={ key } row={ row }>
                  <TableRow
                     role={ role }
                     row={ row }
                     index={ i }
                     updateMyData={ updateMyData }
                  />
               </WithRowDisabled>
            } ) }
         </div>

         <DeletePanel
            show={ !!selectedRows.length && isVisibleDeletePanel }
            cancel={ () => setIsVisibleDeletePanel( false ) }
            thunkDelete={ deleteOrders }
            deleteSuccessText={ {
               title: 'Заявка удалена',
               description: 'Теперь её нет в списке'
            } }
         />
      </div>
   )
}

export default Table