import s from "../OrderManually/OrderManually.module.scss"
import { Button } from "@megafon/ui-core";
import { Form, Formik } from "formik"
import Fieldset from "../../../components-ui/Fieldset/Fieldset";
import Range from "../../../components-ui/Range/Range";
import useValidateOrder from "../../../hooks/useValidateOrder";
import { setOrderData } from "../../../redux/orderSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { TOrderFormStep2 } from "../../../utils/types";


function OrderManuallyStep2() {
   const dispatch = useAppDispatch()
   const {
      account_name,
      ccmp,
      msisnd,
      before_order_number,
   } = useAppSelector( store => store.order.data as TOrderFormStep2 )

   const { required, fields, validationSchema } = useValidateOrder( [
      'account_name', 'ccmp', 'msisnd', 'before_order_number'
   ] )

   function submitHandler( data: TOrderFormStep2 ) {
      dispatch( setOrderData( data ) )
   }


   return (
      <div className={ s.OrderManually }>

         <Range className={ s.OrderManually__range } range={ 45 }/>
         <Formik
            initialValues={ {
               account_name,
               ccmp,
               msisnd,
               before_order_number,
            } }
            validateOnBlur
            validationSchema={ validationSchema }
            onSubmit={ submitHandler }>
            { formik =>
               <Form className={ s.OrderManually__form }>
                  <Fieldset
                     fields={ fields }
                     formik={ formik }
                     required={ required }
                  />
                  {/*@ts-ignore*/ }
                  <Button
                     actionType="submit"
                     className={ s.OrderManually__btn }
                  >Далее</Button>

               </Form>
            }
         </Formik>

      </div>
   )
}

export default OrderManuallyStep2