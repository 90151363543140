import { TOrderExel, TUser } from "../types";


export default function changeAttr( arr: TOrderExel[], user: TUser ) {
   return arr.map( el =>
      Object.fromEntries(
         Object.entries( el ).map( ( [ key, val ] ) => {
            switch ( key ) {
               case 'Тип переноса':
                  return [ 'type', val ]
               case 'Услуга':
                  return [ 'transfer', val ]
               case 'CRM':
                  return [ 'crm', val ]
               case 'CCMP':
                  return [ 'ccmp', val ]
               case 'MSISND':
                  return [ 'msisnd', val ]
               case 'Согласование':
                  return [ 'status', val ]
               case 'Дата внесения':
                  return [ 'createdat', val ]
               case 'Номер заявки':
                  return [ 'before_order_number', val ]
               case 'Кто внес позицию':
                  return [ 'author', user?.name ]
               case 'Ответственный 1':
                  return [ 'responsible_id', val ]
               case 'Ответственный 2':
                     return [ 'responsible_agent_id', val ]
               case 'Бывший продавец':
                  return [ 'ex_seller', val ]
               case 'Будущий продавец':
                  return [ 'next_seller', val ]
               case 'Причина переноса':
                  return [ 'cause_transfer', val ]
               case 'Причина отказа':
                  return [ 'cause_rejection', val ]
               case 'Учетное имя':
                  return [ 'account_name', val ]
               case 'Дубли заявок':
               case 'Авто-согласование':
                  return []
               default:
                  return [ key, val ]
            }
         } )
      )
   )
}
