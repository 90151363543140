export const ORDERS_ALL = 'ordersAll'
export const ORDERS_NO_AGREED = 'ordersNoAgreed'
export const ORDERS_AGREED = 'ordersAgreed'
export const ORDERS_WATCH = 'ordersWatch'
export const OPERATORS_ALL = 'operatorsAll'

export const STATUS = {
   APPROVE: 'Согласовано',
   NO_APPROVE: 'Не согласовано',
   WATCH: 'На рассмотрении',
} as const

export const ROLE = {
   ADMIN: 'Администратор',
   OPERATOR_S_A: 'Ответственный 2',
   OPERATOR_S: 'Ответственный 1',
   OPERATOR: 'Пользователь',
} as const

export const TYPE = {
   PROTOCOL: 'По протоколу',
   IN_SECTOR: 'Внутри сектора',
   IN_SECTOR_AGENT: 'Внутри сектора Агенты',
   RESALE: 'Допродажа',
   OTHER: 'Прочее',
} as const

export const TRANSFER = {
   FMC: 'Конвергент FMC',
   SIM: 'SIM',
   LTE: 'LTE',
   WIFIRE: 'Интернет Wifire',
   TV: 'ТВ',
   PHONE: 'Телефония',
   CONSOLE: 'Приставка',
   ROUTER: 'Роутер',
   OTHER: 'Прочее оборудование'
} as const

export const COMPANY = {
   NBN: 'NBN',
   MF: 'МегаФон',
} as const

export const DEPARTMENT = {
   B2C: 'B2C',
   B2B: 'B2B',
} as const