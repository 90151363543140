// @ts-nocheck
import s from './Table.module.scss'
import { FC, useCallback, useEffect, useState } from "react";
import { Select } from "@megafon/ui-core";
import { Column, Row } from "react-table";
import CellField from "../../components-ui/CellField/CellField";
import useModal from "../../hooks/useModal";
import useSelectResponsibles from "../../hooks/useSelectResponsibles";
import UseSelectResponsiblesAgent from "../../hooks/UseSelectResponsiblesAgent";
import HolidayResponsible from "../Modal/HolidayResponsible/HolidayResponsible";
import Modal from "../Modal/Modal";


type TEditableCellProps = {
   value: string
   row: Row,
   column: Column,
   updateMyData: () => any
}

const EditableCell: FC<TEditableCellProps> = ( { value, row, column, updateMyData } ) => {
   const initialValue = value
   const { index } = row
   const { id, CellTitle, options, disabled, type } = column
   const [ inputValue, setInputValue ] = useState( initialValue )
   const { optionsResponsibles, getHolidayResponsibleId } = useSelectResponsibles()
   const { optionsResponsiblesAgent} = UseSelectResponsiblesAgent()
   const { visible, closeModal, showModal } = useModal()
   const opTionResposible = id === 'responsible_id' ? optionsResponsibles : optionsResponsiblesAgent;
   const isResponsible = id === 'responsible_id' || id === 'responsible_agent_id'
   const items = isResponsible ? opTionResposible : options
   const [ responsibleHoliday, setResponsibleHoliday ] = useState( '' ) 
   const [ valueForSelectTitle, setValueForSelectTitle ] = useState( () => {
      if ( !isResponsible ) return value
      const currentSelectOption = opTionResposible.find( el => el.value === value )
      return currentSelectOption?.title
   } )

   /** Записать новое значение при изменении ячейки */
   function onChange( e ) {
      setInputValue( e.target.value )
   }

   /** Обновить внешние данные */
   const onBlur = useCallback( () => {
      if ( initialValue !== inputValue ) updateMyData( {
         rowIndex: index,
         columnId: id,
         value: inputValue,
      } )
   }, [ index, id, inputValue, initialValue ] )

   /** Обновить внешние данные Select */
   const onChangeSelect = useCallback( ( _, select ) => {
      
      if (select) {
         const responsibleHoliday = getHolidayResponsibleId( select.value )
         if ( responsibleHoliday ) {
            setResponsibleHoliday( responsibleHoliday.name )
            showModal()

            setValueForSelectTitle( responsibleHoliday.name )
            updateMyData( {
               rowIndex: index,
               columnId: id,
               value: responsibleHoliday.id,
            } )
            return
         }

         setValueForSelectTitle( select.title )
         updateMyData( {
            rowIndex: index,
            columnId: id,
            value: select.value || value,
         } )
      } else {
         setValueForSelectTitle( '' )
         setInputValue( '' )
      }
   }, [ index, id, value, initialValue ] )

   /** Очистить поле input */
   // function onClear( e ) {
   //    e.preventDefault()
   //    setInputValue( '' )
   // }

   // Если начальное значение изменено внешне, синхронизируйте его с нашим состоянием.
   useEffect( () => {
      setInputValue( initialValue )
   }, [ initialValue ] )


   return (
      <>
         { options
            ? <div title={ valueForSelectTitle } className={ s.select }><Select
               type="combobox"
               label={ CellTitle }
               onSelect={ onChangeSelect }
               items={ items }
               currentValue={ inputValue }
               disabled={ row.disabled || disabled }
            /></div>
            : <CellField
               type={ type }
               value={ inputValue }
               label={ CellTitle }
               onChange={ onChange }
               onBlur={ onBlur }
               disabled={ row.disabled || disabled }
            /> }

         <Modal onClose={ closeModal } isShow={ visible }>
            <HolidayResponsible responsibleName={ responsibleHoliday } closeModal={ closeModal }/>
         </Modal>
      </>

   )
}

/** Установите наш редактируемый модуль визуализации ячеек в качестве средства визуализации ячеек по умолчанию. */
const defaultColumn = {
   Cell: EditableCell,
}

export default defaultColumn
