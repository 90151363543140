import s from "./HeaderTabs.module.scss"
import { FC } from "react";
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { Tab, Tabs } from "@megafon/ui-core"
import { useAppSelector } from "../../../redux/store";
import { ROLE } from "../../../utils/constants";
import ROUTES from "../../../utils/routes";


type TProps = {
   className?: string
}

const HeaderTabs: FC<TProps> = ( { className = '' } ) => {
   const { user } = useAppSelector( state => state.auth )
   const navigate = useNavigate()
   const location = useLocation()

   function onTabClick( i: number ) {
      if ( i === 0 ) navigate( ROUTES.ORDERS )
      if ( i === 1 ) navigate( ROUTES.OPERATORS )
   }


   if ( user?.role !== ROLE.ADMIN || location.pathname === ROUTES.SETTINGS ) return null
   return (
      <div className={ `${ s._ } ${ className }` }>
         <Tabs
            autoWidth
            onTabClick={ onTabClick }
            currentIndex={ location.pathname === ROUTES.ORDERS ? 0 : 1 }
         >
            <Tab title={ 'Заявки' }/>
            <Tab title={ 'Операторы' }/>
         </Tabs>
      </div>
   )
}


export default HeaderTabs