import s from "../OrderManually/OrderManually.module.scss"
import { Button } from "@megafon/ui-core";
import { Form, Formik } from "formik"
import Fieldset from "../../../components-ui/Fieldset/Fieldset";
import Range from "../../../components-ui/Range/Range";
import { setOrderData } from "../../../redux/orderSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import getFields from "../../../utils/helpers/getFields";
import { TOrderFormStep1 } from "../../../utils/types";


function OrderManuallyStep1() {
   const dispatch = useAppDispatch()
   const { user } = useAppSelector( state => state.auth )
   const { type, transfer, crm, } = useAppSelector( store => store.order.data as TOrderFormStep1 )

   const initialValues: TOrderFormStep1 = { type, transfer, crm }
   const { fields, validationSchema } = getFields( [ 'type', 'transfer', 'crm' ] )

   function submitHandler( data: TOrderFormStep1 ) {
      dispatch( setOrderData( {
         ...data,
         author: user?.name,
         author_id: user?.id,
      } ) )
   }


   return (
      <div className={ s.OrderManually }>

         <Range className={ s.OrderManually__range }/>
         <Formik
            initialValues={ initialValues }
            validateOnBlur
            validationSchema={ validationSchema }
            onSubmit={ submitHandler }>
            { formik =>
               <Form className={ s.OrderManually__form }>
                  <Fieldset fields={ fields } formik={ formik }/>
                  {/*@ts-ignore*/ }
                  <Button
                     actionType="submit"
                     className={ s.OrderManually__btn }
                  >Далее</Button>
               </Form>
            }
         </Formik>

      </div>
   )
}

export default OrderManuallyStep1