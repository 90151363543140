import { useAppDispatch, useAppSelector } from "../redux/store";
import { thunkGetOrders } from "../redux/tableOrders/tableOrdersThunks";
import changeAttr from "../utils/helpers/changeAttr";
import { TOrderExel, TUser } from "../utils/types";


// function deleteDuplicate<T>( arr: T[] ): string[] {
//    const arrToJson = arr.map( el => JSON.stringify( el ) )
//    return [ ...new Set( arrToJson ) ].map( el => JSON.parse( el ) )
// }


function UseOrdersRequest() {
   const dispatch = useAppDispatch()

   const selectPagination = (state: any) => state.tableOrders.pagination.current;
   const selectId = (state: any) => state.tableOrders.selectedId;
   const selectTab = (state: any) => state.tableOrders.tab.value;
   const selectSearch = (state: any) => state.tableOrders.search;
   // const selectSortStatus = (state: any) => state.tableOrders.sortStatus;
   const selectUser = (state: any) => state.auth.user;
   const selectUser_id = (state: any) => state.tableOrders.filterBy ? state.auth.user?.id ?? '' : '';
   const selectFilterBy = (state: any) => state.tableOrders.filterBy;
   const pagination = useAppSelector(selectPagination);
   const id = useAppSelector(selectId);
   const tab = useAppSelector(selectTab);
   const search = useAppSelector(selectSearch);
   // const sortStatus = useAppSelector(selectSortStatus);
   const user = useAppSelector(selectUser);
   const user_id = useAppSelector(selectUser_id);
   const filterBy = useAppSelector(selectFilterBy);
 

   const requestPayload = {
      tab,
      pagination,
      search,
      filterBy,
      user_id
   }

   function getFormData( data: any ) {
      const formData = new FormData()

      Object.keys( data ).forEach( ( key ) => {

         if ( key === 'files' && data.files ) {
            return data.files.forEach( ( file: File, i: number ) => formData.append( 'img' + i, file ) )
         }

         formData.append( key, data[key as keyof object] )
      } )
      return formData
   }

   return {
      getOrders: ( query: string ) => dispatch( thunkGetOrders( {
         method: 'GET',
         query
      } ) ),
      createOrder: ( formData: FormData ) => dispatch( thunkGetOrders( {
         method: 'POST',
         payload: formData
      } ) ),
      updateOrder: ( row: string, files?: File[] ) => dispatch<any>( thunkGetOrders( {
         method: 'PUT',
         payload: getFormData( {
            ...requestPayload,
            row,
            files,
         } )
      } ) ),
      uploadOrders: ( orders: TOrderExel[] ) => dispatch( thunkGetOrders( {
         method: 'POST',
         query: '/xlsx',
         payload: changeAttr( orders, user as TUser )
      } ) ),
      deleteOrders: () => dispatch( thunkGetOrders( {
         method: 'DELETE',
         payload: {
            ...requestPayload,
            id,
         }
      } ) )

   }
}

export default UseOrdersRequest;