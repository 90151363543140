// @ts-nocheck
import s from "./TableOperatos.module.scss";
import { useEffect, useState } from "react";
import { useRowSelect, useTable } from "react-table";
import TableRow from "./TableRow";
import { operatorColumns as columns } from "../../data/table";
import useAlert from "../../hooks/useAlert";
import useOperatorsRequest from "../../hooks/useOperatorsRequest";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { selectOperators, setCellOperators, } from "../../redux/tableOperators/tableOperatorsSlice";
import checkboxToggleHandler from "../../utils/helpers/checkboxToggleHandler";
import DeletePanel from "../DeletePanel/DeletePanel";
import defaultColumn from "../Table/EditableCell";
import TableColumn from "../Table/TableColumn";


function TableOperators() {
   const dispatch = useAppDispatch()
   const { alertSuccess } = useAlert()
   const { updateOperator, deleteOperators } = useOperatorsRequest()
   const data = useAppSelector( state => state.tableOperators.operators )
   const [ rowIndexChanged, setIndexRowChanged ] = useState( null )
   const [ isVisibleDeletePanel, setIsVisibleDeletePanel ] = useState( false )

   const [ skipPageReset, setSkipPageReset ] = useState( false )
   const {
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
   } = useTable( { columns, data, defaultColumn, autoResetPage: !skipPageReset, updateMyData },
      useRowSelect,
      hooks => checkboxToggleHandler( hooks, setIsVisibleDeletePanel )
   )

   /** Изменить данные таблицы */
   function updateMyData( { rowIndex, columnId, value } ) {
      dispatch( setCellOperators( { rowIndex, columnId, value } ) )
      setIndexRowChanged( rowIndex )
   }

   useEffect( () => {
      ( async function () {
         if ( rowIndexChanged !== null ) {
            const res = await updateOperator( rowIndexChanged )
            if ( !res.error ) alertSuccess( 'Данные сохранены!' )
            setIndexRowChanged( null )
         }
      } )()
   }, [ rowIndexChanged ] )

   useEffect( () => {
      // Включаем флаг, чтобы не сбрасывать страницу
      setSkipPageReset( false )
   }, [ data ] )

   useEffect( () => {
      dispatch( selectOperators( selectedFlatRows.map( d => d.original.id ) ) )
   }, [ selectedFlatRows ] )


   return (
      <div className={ s.Table }>
         { headerGroups.map( headerGroup => {
               const { key, role } = headerGroup.getHeaderGroupProps()
               return <div key={ key } role={ role } className={ s.Table__head }>
                  { headerGroup.headers.map( column =>
                     <TableColumn key={ column.id } column={ column }/>
                  ) }
                  <div></div>
               </div>
            }
         ) }

         { rows.map( row => {
            prepareRow( row )
            const { key, role } = row.getRowProps()
            return <TableRow key={ key } role={ role } row={ row }/>
         } ) }

         <DeletePanel
            show={ !!selectedFlatRows.length && isVisibleDeletePanel }
            thunkDelete={ deleteOperators }
            cancel={ () => setIsVisibleDeletePanel( false ) }
            deleteSuccessText={ {
               title: 'Оператор удалён',
               description: 'Теперь его нет в списке'
            } }
         />
      </div>
   )
}

export default TableOperators