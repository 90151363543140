import s from './ButtonResetOperator.module.scss'
import { useState } from 'react';
import { Button } from "@megafon/ui-core";
import { ReactComponent as Login } from '@megafon/ui-icons/basic-16-login_16.svg'; 

import useModal from "../../hooks/useModal";
import useOperatorsRequest from "../../hooks/useOperatorsRequest";
import Modal from "../Modal/Modal";
import OrderSuccess from "../Modal/OrderSuccess/OrderSuccess";

import WithModalTitle from "../Modal/WithModalTitle/WithModalTitle"; 

function ButtonResetOperator( { email }: {
    email: string
 }  ) {  
   const [ success, setSuccess ] = useState( false )
   const { visible, showModal, closeModal } = useModal()

   const { resetPassword } = useOperatorsRequest()

   async function closeModalHandler(): Promise<void> {
      await closeModal()
      setSuccess( false )
   }
   async function submitHandler(   ) {
        const res = await resetPassword( email )
        { /*@ts-ignore*/ }
        setSuccess( !res.error ) 
    }

   return (
      <> 
        {/*@ts-ignore*/ }
        <Button onClick={ showModal } theme="purple" icon={<Login />}>
            сброс
        </Button>
         <Modal onClose={ closeModal } isShow={ visible }>
            { !success &&
               <WithModalTitle
                  title={ 'Сброс пароля' }
                  description={ `Пароль будет сброшен и отправлен на почту <strong>${email}</strong>.<br/>Продолжить?` }
               > 
                <div className={ s.ButtonReset }>
                { /*@ts-ignore*/ }
                <Button onClick={ submitHandler }
                  >
                     Сбросить пароль
                  </Button>
                  </div>
               </WithModalTitle>
            }

            { success &&
               <OrderSuccess
                  title={ 'Пароль сброшен' }
                  description={ `Новый пароль отправлен на почту ${email}` }
                  closeModal={ closeModalHandler }
               />
            }
         </Modal>
      </>
   )
}


export default ButtonResetOperator;