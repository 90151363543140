import s from "../../pages/MainPage.module.scss";
import { FC, PropsWithChildren, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useOperatorsRequest from "../../hooks/useOperatorsRequest";
import { useAppSelector } from "../../redux/store";
import MainHeader from "../MainHeader/MainHeader";


/** HOK
 * - Если пользователь не авторизован, то редирект на login */
const RequireAuth: FC<PropsWithChildren> = ( { children } ) => {
   const location = useLocation()
   const auth = useAppSelector( state => state.auth )
   const { getResponsibles } = useOperatorsRequest()

   // записать ответственных
   useEffect( () => {
      getResponsibles()
   }, [] )


   if ( auth.user === null ) return <Navigate to="/login" state={ { from: location.pathname } } replace/>
   return (
      <div className={ s.MainPage }>
         <MainHeader/>
         <div className={ `${ s.MainPage__container } wrapper` }>
            { children }
         </div>
      </div>
   )
}

export default RequireAuth