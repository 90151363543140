import s from "./SettingsPage.module.scss";
import UserContactNameRole from "../../components/UserContacts/UserContactNameRole/UserContactNameRole";
import UserContacts from "../../components/UserContacts/UserContacts";
import UserHoliday from "../../components/UserHoliday/UserHoliday";
import { useAppSelector } from "../../redux/store";
import { ROLE } from "../../utils/constants";


function SettingsPage() {
   const user = useAppSelector( state => state.auth.user )

   if ( !user ) return null
   return (
      <div className={ s._ }>
         <UserContactNameRole user={ user }/>
         <UserContacts user={ user }/>
         { user.role !== ROLE.OPERATOR && <UserHoliday user={ user }/> }
      </div>
   )
}


export default SettingsPage;