import { createAsyncThunk } from "@reduxjs/toolkit";
import { Method } from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { changedOff } from "./tableOrdersSlice";
import { BASE_URL, request } from "../../utils/api";
import createBlob from "../../utils/helpers/createBlob";
import { TRow, TThunkOrderResponse } from "../../utils/types";


export const thunkGetExcelOrders = createAsyncThunk<
   any,
   { method: Method, query?: string, payload?: any }
>(
   'tableOrders/thunkGetExcelOrders',
   async ( { method, query = '', payload }, { rejectWithValue } ) => {
      try {
         const { data, status } = await request( `orders${ query }`, {
            method,
            data: payload
         } )
         if ( status === 200 ) return data
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)

export const thunkGetOrders = createAsyncThunk<
   TThunkOrderResponse,
   { method: Method, query?: string, payload?: any }
>(
   'tableOrders/thunkGetOrders',
   async ( { method, query = '', payload }, { dispatch, rejectWithValue } ) => {
      try {
         const { data, status } = await request( `orders${ query }`, {
            method,
            data: payload
         } )
         if ( status === 200 ) {
            if ( method === 'PUT' ) {
               const row = JSON.parse( payload.get( 'row' ) )
               dispatch( changedOff( row.id ) )
            }
            return data
         }
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)

export const thunkDownloadFiles = createAsyncThunk<void, TRow>(
   'tableOrders/thunkDownloadFiles',
   async ( row, { rejectWithValue } ) => {
      try {
         const zip = new JSZip()
         const images = row.images && JSON.parse( row.images )
         images.forEach( ( fileName: string ) => {
            const file = createBlob( `${ BASE_URL }${ fileName }`, fileName )
            zip.file( fileName, file )
         } )
         const content = await zip.generateAsync( { type: 'blob' } )
         saveAs( content, `${ row.id }` )
      } catch ( err: any ) {
         return rejectWithValue( err.response?.data.message || err.message )
      }
   }
)