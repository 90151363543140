import { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/store";


/** HOK
 * - если пользователь авторизован, то редирект на главную
 * */
const RequireNoAuth: FC<PropsWithChildren> = ( { children } ) => {
   const auth = useAppSelector( state => state.auth )
   const location = useLocation()

   if ( auth.user ) {
      return <Navigate to="/orders" state={ { from: location.pathname } } replace/>
   }

   return <>{ children }</>
}

export default RequireNoAuth