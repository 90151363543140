import { useEffect } from "react";
import useSelectGetNameResponsible from "../../../hooks/useSelectGetNameResponsible";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { clearUploadInfo } from "../../../redux/tableOrders/tableOrdersSlice";


/** Информация о переносе ответственных и загруженных заявках */
function UploadInfo() {
   const dispatch = useAppDispatch()
   const { resend_responsibles, counter_on_responsible } = useAppSelector( state => state.tableOrders )
   if ( !resend_responsibles && !counter_on_responsible ) return null
   const { getUserNameById } = useSelectGetNameResponsible()

   const holidayResponsibles = resend_responsibles?.map( el => {
      const responsibleNameFrom = getUserNameById( el.from )
      const responsibleNameTo = getUserNameById( el.to )
      return `${ responsibleNameFrom } в отпуске, заменен на ${ responsibleNameTo }.`
   } )

   const countResponsibles = counter_on_responsible && Object.entries( counter_on_responsible ).reduce( (
      acc: { total: number, text: string[] },
      [ userId, count ] ) => {
      const responsibleName = getUserNameById( +userId )
      acc.text.push( `${ count } шт. назначены на ${ responsibleName }` )
      acc.total += count
      return acc
   }, { total: 0, text: [] } )


   useEffect( () => () => {
      dispatch( clearUploadInfo() )
   }, [] )


   return (
      <>
         { holidayResponsibles &&
            <ul>
               { holidayResponsibles.map( text =>
                  <li key={ text }>{ text }</li>
               ) }
            </ul>
         }
         { countResponsibles &&
            <>
               <p>Добавлено { countResponsibles.total } заявок:</p>
               <ul>
                  { countResponsibles.text.map( text =>
                     <li key={ text }>{ text }</li>
                  ) }
               </ul>
            </>
         }
      </>
   )
}


export default UploadInfo;