import s from "./UserContacts.module.scss";
import { ReactComponent as EmailIcon } from '@megafon/ui-icons/basic-16-mail_16.svg';
import { ReactComponent as PhoneIcon } from '@megafon/ui-icons/basic-16-phone_16.svg';
import UserContactCard from "./UserContactCard/UserContactCard";
import { TUser } from "../../utils/types";


function UserContacts( { user }: {
   user: TUser
} ) {

   const userInfoCards = [
      { icon: <EmailIcon/>, title: 'Электронная почта', text: user.email },
      { icon: <PhoneIcon/>, title: 'Мобильный телефон', text: user.phone },
      // { title: 'Руководитель', value: user.supervisor },
   ]


   return (
      <div className={ s._ }>
         <h3 className={ s.title }>Контактная информация:</h3>

         <ul className={ s.list }>
            { userInfoCards.map( card =>
               <UserContactCard
                  key={ card.title }
                  text={ card.text }
                  title={ card.title }
                  icon={ card.icon }
               />
            ) }
         </ul>

      </div>
   )
}


export default UserContacts;