import useRole from "./useRole";
import { STATUS, TYPE } from "../utils/constants";
// import getDate, { mounts } from "../utils/helpers/getDate";
import { TRow } from "../utils/types";
const { addBusinessDays, isWeekend } = require('date-fns');


function UseHelpMessage( row: TRow ) {
   const {
      isStatusWatch,
      isOperator,
      isAdmin,
      isAuthorId,
      isResponsible,
      isOperatorSDisabledAfterOneMonth,
   } = useRole( row )
   const {
      confirmed,
      autoconfirmed,
      createdat,
      status,
      status2,
      status2s,
      closed,
      comment,
      type,
   } = row
 
 
   function getThreeBusinessDaysAhead() {
      const today = new Date(createdat);
      
      // Если сегодня выходной (суббота или воскресенье), нужно перенести начало отсчета на следующий понедельник
      if (isWeekend(today)) {
        const daysToAdd = today.getDay() === 6 ? 2 : 1; // Если суббота -> добавляем 2 дня, если воскресенье -> добавляем 1 день
        today.setDate(today.getDate() + daysToAdd);
      }
    
      // Добавляем 3 рабочих дня к текущей дате
      const threeBusinessDaysAhead = addBusinessDays(today, 3);
    
      return threeBusinessDaysAhead;
    }
    

   const closeDate = getThreeBusinessDaysAhead();

   const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    
    const formattedDate = closeDate.toLocaleString('ru-RU', options);
    
 

   
   const isYour = isResponsible ? 'Вам необходимо' : 'Ответственному необходимо'
   const periodHasExpired = isOperatorSDisabledAfterOneMonth ? ' (Срок согласования истёк)' : ''

   const dupleConfirmOrder = confirmed && status === STATUS.WATCH ? 'Дубль. ' : ''

   const isSectorAgent = type === TYPE.IN_SECTOR_AGENT;
   const isSector = type === TYPE.IN_SECTOR_AGENT || type === TYPE.IN_SECTOR;


   /**
    * Сообщение относящееся к статусу заявки
    * @returns {`Заявка еще не согласована. Вам необходимо её согласовать до ${number} ${string} ${number} года.` |
    *    `Заявка еще не согласована. Необходимо её согласовать до ${number} ${string} ${number} года.` | `Заявка еще не
    *    согласована. Вам необходимо её согласовать до 5 ${string} ${number} года.` | `Заявка еще не согласована.
    *    Необходимо её согласовать до 5 ${string} ${number} года.` | "На рассмотрении!" | "Согласовано!" | "Не
    *    согласовано!" | string}
    */
   const helpMessageDone = (): string => {

      if ( closed ) return dupleConfirmOrder + 'Заявка закрыта.'

      if ( comment === "Согласована внутри сектора" ) return "Согласована заявка внутри сектора";
      if ( isStatusWatch ) {

         if ( !confirmed ) {
            if ( isOperator ) return ''
            if (isSectorAgent ) {
               return `Ответственный 1: "${ status2 }" 
               Ответственный 2: "${ status2s }" `
            }
            if ( isSector )  return `Заявка еще не согласована.`

            return `Заявка еще не согласована. ${ isYour } её согласовать до ${ formattedDate } включительно.` +
               periodHasExpired
         }
         return 'Дубль. Эта заявка уже рассмотрена.'

      }
      if (isSectorAgent ) {
         return `Ответственный 1: "${ status2 }" 
         Ответственный 2: "${ status2s }" `
      }

      

      return `${ status }! ${ autoconfirmed ? ' (автоматически)' : '' }`
   }

   /**
    * Сообщение относящееся к редактированию заявки
    * @returns {`Менять статус согласования можно до 5 ${string} ${number} года включительно.` | `Вносить правки в
    *    данную заявку можно до 5 ${string} ${number} года включительно.` | `Менять статус согласования можно до
    *    ${number} ${string} ${number}  года включительно.` | `Вносить правки в данную заявку можно до ${number}
    *    ${string} ${number} года.` | string}
    */
   const helpMessageWarning = (): string => {
      if ( closed ) return 'Редактирование недоступно.'

      

      if ( isAdmin ) {

         if ( confirmed && !isStatusWatch ) {
            if ( isSector )  return `Редактирование недоступно.`
            return `Редактирование недоступно. 
            Менять статус согласования можно до ${ formattedDate } включительно.`
         }

         if ( isSector )  return `Заявка еще не согласована.`
         return `Вносить правки в данную заявку можно до ${ formattedDate } включительно.`
      }

      if ( confirmed && !isStatusWatch ) {

         if ( isResponsible ) {
            if ( isSector )  return `Редактирование доступно.`
            return `Менять статус согласования можно до ${ formattedDate } включительно.`
         }

         return 'Редактирование недоступно.'
      }

      if ( isAuthorId ) {
         if ( isSector )  return `Заявка еще не согласована.`
         return `Вносить правки в данную заявку можно до ${ formattedDate } включительно.`
      }

      return `Редактирование недоступно. 
      Вносить правки в данную заявку может автор заявки.`
   }


   return {
      helpMessageDone,
      helpMessageWarning,
   }
}


export default UseHelpMessage;
