import s from './SelectFilter.module.scss'
import { Select } from "@megafon/ui-core";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { selectOrdersFilter } from "../../redux/tableOrders/tableOrdersSlice";
import { TFilterBy } from "../../utils/types";


function SelectFilter() {
   const filterBy = useAppSelector( state => state.tableOrders.filterBy )
   const dispatch = useAppDispatch()

   function onSelect( e: any, select: any ) {
      dispatch( selectOrdersFilter( select.value as TFilterBy ) )
   }


   return (
      <Select
         label={ 'Фильтр' }
         currentValue={ filterBy }
         onSelect={ onSelect }
         className={ s._ }
         shortList
         items={ [
            { title: 'Все', value: '' },
            { title: 'Мои заявки', value: 'user_id' },
            { title: 'Я ответственный 1', value: 'responsible_id' },
            { title: 'Я ответственный 2', value: 'responsible_agent_id' },
         ] }/>
   )
}


export default SelectFilter;