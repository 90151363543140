import s from './DownloadOrder.module.scss'
import { useState } from 'react'
import { Calendar } from "@megafon/ui-core";
import ModalOverlay from "../ModalOverlay/ModalOverlay";


type TDownloadDatepicker = {
   date: Record<string, Date | null>,
   setDate: ( date: Record<string, Date | null> ) => void
}

const maxBookingDate: Date = new Date()


function DownloadDatepicker( { date, setDate }: TDownloadDatepicker ) {
   const [ isVisible, setIsVisible ] = useState( false )

   function formatDate( date: Date | null ): string | null {
      return date?.toLocaleString( 'ru', { day: 'numeric', month: 'long', } ) ?? null
   }

   const onChange = ( currentStartDate: Date | null, currentEndDate: Date | null ): void => {
      setDate( {
         from: currentStartDate,
         to: currentEndDate
      } )
   }

   function openDatepicker(): void {
      setIsVisible( true )
   }

   function closeDatepicker(): void {
      setIsVisible( false )
   }


   return (
      <div className={ s.downloadDatepicker }>
         <i className={ s.calendarIcon }/>
         Выберите период: <span className={ 'link' } onClick={ openDatepicker }>
         с { formatDate( date.from ) || 'не указано' } по { formatDate( date.to ) || 'не указано' }</span>

         { isVisible && <>
            <ModalOverlay transparent onClose={ closeDatepicker }/>
            <Calendar
               className={ s.calendar }
               onChange={ onChange }
               maxBookingDate={ maxBookingDate }
            />
         </> }
      </div>
   )
}

export default DownloadDatepicker