import { TOperator } from "../types";


/**
 * Получить id по имени оператора
 * @param filedOptions
 * @param value
 * @returns {number}
 */
function getSelectTitleByValue( filedOptions: TOperator[], value: string | number ): number {
   const user = filedOptions.find( el => el.name === value )
   return user?.id ?? 0
}

export default getSelectTitleByValue;