import s from './Checkbox.module.scss'
import { ChangeEvent, forwardRef, useEffect, useRef } from 'react'
import useRowDisabled from "../../hooks/useDisabledCheckbox";

type TProps = {
   indeterminate: boolean
   setIsVisibleDeletePanel: ( state: boolean ) => void
   row?: any
   disabled?: boolean
}

/* eslint-disable react/display-name */
const Checkbox = forwardRef<HTMLInputElement, TProps>(
   ( { indeterminate, setIsVisibleDeletePanel, row, disabled = false, ...rest }, ref ) => {
      const defaultRef = useRef<HTMLInputElement>( null )
      const resolvedRef = ref || defaultRef
      const { isCheckboxDisabled } = useRowDisabled()

      useEffect( () => {
         // @ts-ignore
         resolvedRef.current.indeterminate = indeterminate
      }, [ resolvedRef, indeterminate ] )

      function onInput( e: ChangeEvent<HTMLInputElement> ) {
         if ( e.target.checked ) setIsVisibleDeletePanel( true )
      }


      return (
         <>
            <label className={ s.Checkbox }>
               <input
                  type="checkbox"
                  ref={ resolvedRef }
                  { ...rest }
                  onInput={ onInput }
                  disabled={ disabled || row?.disabled || isCheckboxDisabled( row ) }
               />
               <span/>
            </label>
         </>
      )
   }
)


export default Checkbox