import { HashRouter } from "react-router-dom"
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux"
import './assets/styles/main.scss'
import App from './components/App'
import store from "./redux/store"


ReactDOM.createRoot( document.getElementById( 'root' ) as HTMLElement ).render(
   <HashRouter>
      <Provider store={ store }>
         <App/>
      </Provider>
   </HashRouter>
)
